/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');
var MomentCell = require('common/backgrid/MomentCell');
var AddressCell = require('common/backgrid/AddressCell');
var modelGet = require('common/util/modelGet');

var tmplNonDepLicenseAddressHistoryView = require('../templates/NonDepLicenseAddressHistoryView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseAddressHistoryView,

  className: 'license-address-history-view',

  ui: {
    addressHistoryContainer: '.address-history-container'
  },

  regions: {
    addressHistoryContainer: '@ui.addressHistoryContainer'
  },

  onBeforeShow: function() {
    this.addressHistoryGrid = new GridView({
      columns: [
        {
          name: 'createdDate',
          label: 'Date Changed',
          cell: MomentCell.MomentCell,
          formatter: MomentCell.MomentDateTimeFormatter.extend({
            emptyValue: '--'
          }),
          sortValue: MomentCell.MomentSortValue,
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'createdBy',
          label: 'Changed By',
          cell: 'string',
          headerCell: 'custom',
          width: 30,
          editable: false
        },
        {
          name: 'address',
          label: 'Address',
          cell: AddressCell,
          headerCell: 'custom',
          width: 40,
          editable: false
        }
      ],
      collection: new PageableCollection(modelGet(this.model, 'licenseHistory.addressHistory', []), { pageSize: 10 }),
      emptyText: 'No License Address History'
    });

    this.showChildView('addressHistoryContainer', this.addressHistoryGrid);
    this.addressHistoryGrid.viewGrid.sort('createdDate', 'descending');
  }
});
