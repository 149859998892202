/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _get = require('lodash.get');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var _ = require('underscore');

var modelGet = require('common/util/modelGet');
var userHasRole = require('common/util/userHasRole');

require('../styles/ConserComplaintSectionContactView.css');
var tmplConserComplaintSectionContactView = require('../templates/ConserComplaintSectionContactView.hbs');
var ConSerComplaintContactInformationDisplayView = require('./ConSerComplaintContactInformationDisplayView');

module.exports = Marionette.LayoutView.extend({
  template: tmplConserComplaintSectionContactView,

  className: 'conser-complaint-section-contact-view',

  ui: {
    contactForm: '.contact-form',
    contactDetails: '.contact-details',
    contactOldDisplayContainer: '.contact-old-display-container',
    contactInformationContainer: '.contact-information-container',
    isContactUnknown: '[name="isContactUnknown"]',
    nondepWarning: '.nondep-warning',
    formInputs: ':input:not(:button):not(:radio)',
    oldAddressContainer: '.old-address-container',
    addContactButton: '.btn-add-cs-contact'
  },

  regions: {
    contactInformationContainer: '@ui.contactInformationContainer',
    contactOldDisplayContainer: '@ui.contactOldDisplayContainer',
    oldAddressContainer: '@ui.oldAddressContainer'
  },

  events: {
    'change @ui.isContactUnknown': 'onChangeContactUnknown',
    'click @ui.addContactButton': 'onClickAddContact'
  },

  behaviors: {
    ToggleBehavoir: {
      toggles: [
        {
          target: '.contact-details',
          group: '[name="isContactUnknown"]',
          valuesToShow: '0'
        }
      ]
    }
  },

  templateHelpers: function() {
    var isAdministrator = userHasRole('CS - Administrator');
    return {
      isAdministrator: isAdministrator
    };
  },

  onBeforeShow: function() {
    var contact = modelGet(this.model, 'complaint.contact', {});
    this.contact = contact;

    // default the Contact Known radio to "no" if the value is falsy.
    if (!contact.isContactUnknown) {
      contact.isContactUnknown = '1';
    }

    // renders contact information (with select) view
    this.renderContactInformation(contact.isContactUnknown === '0');
    Syphon.deserialize(this, contact);

    // display OLD contact info
    //this.renderOldContactInformation(contact);
  },

  onClickAddContact: function(e) {
    e.preventDefault();
    var newContact = new ConSerComplaintContactInformationDisplayView({
      model: this.contactDisplay.collection.models[0]
    });
    this.contactDisplay.onChildClickAddContact(newContact);
  },

  onChangeContactUnknown: function(e) {
    var isContactKnown = e.target.value === '0';
    this.renderContactInformation(isContactKnown);
  },

  isContactArchived: function() {
    // check status of complaint (open or close)
    var isContactArchived = modelGet(this.model, 'complaint.contact.isContactArchived', {});
    var statusId = modelGet(this.model, 'complaint.statusId', {});

    if (isContactArchived === '1') {
      // contact IS archived
      return true;
    } else if (statusId === '2' || statusId === '3') {
      // complaint closed, contact IS archived
      return true;
    } else {
      // contact NOT archived
      return false;
    }
  },

  validate: function() {
    return this.ui.contactForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = {};
    var form = Syphon.serialize(this);

    //var isContactArchived = this.isContactArchived();
    var results = this.sideNavView.getFormDataBySection('entityIndividualInformation');
    // if the contact IS archived (true), then DO NOT update old contact info
    //console.log(this.contactDisplay.getFormData());
    formData.contact = {};
    var contacts = _.filter(this.contactDisplay.getFormData(), function(contact) {
      return contact.contactId != null;
    });

    contacts = _.map(contacts, function(contact) {
      return {
        departmentId: contact.isEdit === '1' ? 4000 : results.departmentId,
        contactId: contact.contactId
      };
    });
    formData.complaintContacts = _.uniq(contacts, 'contactId');
    /*if (isContactArchived) {
      var oldContact = modelGet(this.model, 'complaint.contact', {});

      formData.contact = oldContact;
      formData.contact.contactId = form.contactId;
      formData.contact.isContactArchived = 1;
    } else {
      // console.log(this)
      var selectedContact = this.contactDisplay.getSelectedContact() || {};

      formData.contact = selectedContact;
      formData.contact.isContactArchived = 0;
    }*/
    formData.contact.isContactUnknown = form.isContactUnknown;
    formData.contact.comments = form.comments;

    return formData;
  },

  renderContactInformation: function(isContactKnown) {
    var self = this;
    var entityIndividualSection = this.getEntityIndividualSectionData();
    // if (isContactKnown) {
    var contactDisplayView = Radio.channel('cs').request(
      'build:contact-information-display',
      entityIndividualSection,
      this.model.attributes.complaint ? this.model.attributes.complaint.complaintContacts : []
    );
    this.showChildView('contactInformationContainer', contactDisplayView);
    this.contactDisplayView = contactDisplayView;

    var placeholderPromise = _get(contactDisplayView, 'options.viewPromise');
    if (placeholderPromise) {
      placeholderPromise.done(function(contactDisplay) {
        self.contactDisplay = contactDisplay;
      });
    }
    // }
  },

  getEntityIndividualSectionData: function() {
    // Calls the getFormData method from the entityIndividualInformation section
    // of the complaint dashboard.
    var results = this.sideNavView.getFormDataBySection('entityIndividualInformation');

    results.complete = true;
    if (!results.entityId || !results.departmentId) {
      results.complete = false;
    }

    return results;
  },

  resetContact: function() {
    this.ui.formInputs.val('');
    // this.renderAddress({});
    this.ui.isContactUnknown.filter('[value="1"]').prop('checked', true);
    this.ui.contactDetails.hide();
  }
});
