/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');
var formatAsDollars = require('common/util/formatAsDollars');

var buildExternalUserDisplay = require('../../common/util/buildExternalUserDisplay');

module.exports = function(examId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetEntityExam: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        ID: examId
      }
    },
    blueprintArrays: [
      'GetEntityExamResponse.ExamConductedBys.DP_ExamConductedBy',
      'GetEntityExamResponse.ExamAreas.DP_ExamArea',
      'GetEntityExamResponse.ExamProcessingResponses.DP_ExamProcessingResponse',
      'GetEntityExamResponse.ExamViolations.DP_ExamViolation',
      'GetEntityExamResponse.ExamConcentrations.DP_ExamConcentration',
      'GetEntityExamResponse.ExamParticipatingAgencies.DP_ExamParticipatingAgency',
      'GetEntityExamResponse.ExamPriorExams.DP_ExamPriorExam'
    ],
    blueprint: {
      id: '$..Exam.ID',
      entityId: '$..Exam.EntityID',
      csFolderId: '$..Exam.CSFolderID',
      comments: '$..Exam.Comments',
      examStatusId: '$..Exam.ExamStatusID',
      examStatus: '$..Exam.ExamStatus',
      examYear: '$..Exam.ExamYear',
      examTypeId: '$..Exam.ExamTypeID',
      examType: '$..Exam.ExamType',
      examScopeId: '$..Exam.ExamScopeID',
      examScope: '$..Exam.ExamScope',
      targetCodes: '$..Exam.TargetCodes',
      targetVisitationCode: '$..Exam.TargetVisitationCode',
      leadAgencyId: '$..Exam.LeadAgencyID',
      leadAgency: '$..Exam.LeadAgency',
      isHoldingCompanyExam: '$..Exam.IsHoldingCompanyExam',
      bhcAsOfDate: '$..Exam.BHCAsOfDate',
      asOfDate: '$..Exam.AsOfDate',
      isNotifyEntity: '$..Exam.IsNotifyEntity',
      dueDate: '$..Exam.DueDate',
      isConcurrent: '$..Exam.IsConcurrent',
      isParticipation: '$..Exam.IsParticipation',
      eic: '$..Exam.EIC',
      initialProcessorReceivedDate: '$..Exam.InitialProcessorReceivedDate',
      initialProcessor: '$..Exam.InitialProcessor',
      sentToCaseManagerDate: '$..Exam.SentToCaseManagerDate',
      caseManager: '$..Exam.CaseManager',
      sentToFinalReviewerDate: '$..Exam.SentToFinalReviewerDate',
      finalReviewer: '$..Exam.FinalReviewer',
      receivedFromFederalAgencyId: '$..Exam.ReceivedFromFederalAgencyID',
      receivedFromFederalAgency: '$..Exam.ReceivedFromFederalAgency',
      stateLeadAgencySentToFedDate: '$..StateLeadAgencySentToFedDate',
      stateLeadAgencyReceivedFromFedDate: '$..StateLeadAgencyReceivedFromFedDate',
      leadAgencyReceivedFromFedDate: '$..Exam.LeadAgencyReceivedFromFedDate',
      leadAgencyReturnedToFedDate: '$..Exam.LeadAgencyReturnedToFedDate',
      scopeSubcategory: '$..Exam.ScopeSubcategory',
      leadAgencyFederalAgencyReceivedID: '$..Exam.LeadAgencyFederalAgencyReceivedID',
      saerCompleteDate: '$..SAERCompleteDate',
      completionDate: '$..CompletionDate',
      finalProcessorReceivedDate: '$..Exam.FinalProcessorReceivedDate',
      finalProcessor: '$..Exam.FinalProcessor',
      finalProcessorSentForTransmittalDate: '$..Exam.FinalProcessorSentForTransmittalDate',
      signoffSSDate: '$..Exam.SignoffSSDate',
      signoffITDate: '$..Exam.SignoffITDate',
      signoffTrustDate: '$..Exam.SignoffTrustDate',
      transmittalDate: '$..Exam.TransmittalDate',
      receivedByEntityDate: '$..Exam.ReceivedByEntityDate',
      examClosedDate: '$..Exam.ExamClosedDate',
      ssRatingC: '$..Exam.SSRatingC',
      ssRatingA: '$..Exam.SSRatingA',
      ssRatingM: '$..Exam.SSRatingM',
      ssRatingE: '$..Exam.SSRatingE',
      ssRatingL: '$..Exam.SSRatingL',
      ssRatingS: '$..Exam.SSRatingS',
      ssRatingComposite: '$..Exam.SSRatingComposite',
      ssRatingBSAId: '$..Exam.SSRatingBSAID',
      ssRatingBSA: '$..Exam.SSRatingBSA',
      itRatingA: '$..Exam.ITRatingA',
      itRatingM: '$..Exam.ITRatingM',
      itRatingD: '$..Exam.ITRatingD',
      itRatingS: '$..Exam.ITRatingS',
      itRatingComposite: '$..Exam.ITRatingComposite',
      trustRatingM: '$..Exam.TrustRatingM',
      trustRatingO: '$..Exam.TrustRatingO',
      trustRatingE: '$..Exam.TrustRatingE',
      trustRatingC: '$..Exam.TrustRatingC',
      trustRatingA: '$..Exam.TrustRatingA',
      trustRatingComposite: '$..Exam.TrustRatingComposite',
      complianceRatingComposite: '$..ComplianceRatingComposite',
      craRatingId: '$..Exam.CRARatingID',
      craRating: '$..Exam.CRARating',
      hcRatingHoldingCompany: '$..Exam.HCRatingHoldingCompany',
      hcRatingComposite: '$..Exam.HCRatingComposite',
      hcRatingRisk: '$..Exam.HCRatingRisk',
      ursitRatingITAudit: '$..Exam.URSITRatingITAudit',
      ursitRatingManagement: '$..Exam.URSITRatingManagement',
      ursitRatingDevAcq: '$..Exam.URSITRatingDevAcq',
      ursitRatingSupportDelivery: '$..Exam.URSITRatingSupportDelivery',
      ursitRatingComposite: '$..Exam.URSITRatingComposite',
      TSPAsOfDate: '$..TSPAsOfDate',
      dataCenter: '$..DataCenter',
      businessLineRiskRankingId: '$..BusinessLineRiskRankingID',
      TSPProviderRiskRankingId: '$..TSPProviderRiskRankingID',
      examPriorityRankingId: '$..ExamPriorityRankingID',
      businessLineRiskRanking: '$..BusinessLineRiskRanking',
      TSPProviderRiskRanking: '$..TSPProviderRiskRanking',
      examPriorityRanking: '$..ExamPriorityRanking',
      agencyAgreementId: '$..AgencyAgreement',
      otherLeadAgency: '$..OtherLeadAgency',
      otherScope: '$..OtherScope',
      actionComments: '$..Exam.ActionComments',
      examOwner: {
        externalUserId: '$..OwnerExternalUserID', // int example: 2003
        firstName: '$..ExamOwnerFirstName',
        lastName: '$..ExamOwnerLastName',
        userId: '$..ExamOwnerUserID' // string example: 'jdoe'
      },

      examConductedBy: [
        '$..DP_ExamConductedBy',
        {
          id: '$.ID',
          examId: '$.ExamID',
          conductedById: '$.ConductedByID',
          conductedBy: '$.ConductedBy'
        }
      ],
      examAreas: [
        '$..DP_ExamArea',
        {
          id: '$.ID',
          examId: '$.ExamID',
          examAreaId: '$.ExamAreaID',
          examArea: '$.ExamArea',
          asOfDate: '$.AsOfDate',
          assetSize: '$.AssetSize',
          isNotifyEntity: '$..IsNotifyEntity',
          firstDayLetterDate: '$.FirstDayLetterDate',
          firstDayLetter: {
            fileId: '$..FirstDayLetterDocID',
            fileName: '$..FirstDayLetterName'
          },
          eic: '$.EIC',
          eicFirstName: '$..EICFirstName',
          eicLastName: '$..EICLastName',
          federalEIC: '$.FederalEIC',
          examStartDate: '$.ExamStartDate',
          exitMeetingDate: '$.ExitMeetingDate',
          firstDayLetterAcknowledgedDate: '$.FirstDayLetterAcknowledgedDate',
          examHours: '$.ExamHours'
        }
      ],
      examProcessingResponses: [
        '$..DP_ExamProcessingResponse',
        {
          id: '$.ID',
          examId: '$.ExamID',
          responseDueDate: '$.ResponseDueDate',
          responseReceivedDate: '$.ResponseReceivedDate',
          acknowledgementSentDate: '$.AcknowledgementSentDate',
          notes: '$.Notes'
        }
      ],
      examViolations: [
        '$..DP_ExamViolation',
        {
          id: '$.ID',
          examId: '$.ExamID',
          entityId: '$.EntityID',
          legalCiteId: '$.LegalCiteID',
          legalCite: '$.LegalCite',
          comments: '$.Comments'
        }
      ],
      examConcentrations: [
        '$..DP_ExamConcentration',
        {
          id: '$.ID',
          examId: '$.ExamID',
          entityId: '$.EntityID',
          typeId: '$.TypeID',
          subTypeId: '$.SubTypeID',
          comments: '$.Comments',
          subType: '$.ConcentrationsSubType',
          concentrationTypeId: '$.ConcentrationTypeID',
          type: '$.ConcentrationsType'
        }
      ],
      examParticipatingAgencies: [
        '$..DP_ExamParticipatingAgency',
        {
          id: '$.ID',
          examId: '$.ExamID',
          nameId: '$.NameID',
          nameDisplay: '$.Name',
          regionDistrictId: '$.RegionDistrictID',
          regionDisplay: '$.RegionDistrict',
          otherRegion: '$.Other',
          isTSP: '$.isTSP',
          categoryId: '$.CategoryID'
        }
      ],
      examPriorExams: [
        '$..DP_ExamPriorExam',
        {
          id: '$.ID',
          examId: '$.ExamID',
          priorExamDate: '$.Date',
          rating: '$.Rating',
          leadAgencyId: '$.LeadAgencyID',
          leadAgency: '$.Option',
          otherLeadAgency: '$.OtherLeadAgency'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      var getExamAreaName = function(area) {
        switch (area.examAreaId) {
          case '1':
          case '11':
            return 'ssArea';
          case '2':
          case '21':
            return 'trustArea';
          case '3':
          case '12':
          case '22':
            return 'itArea';
          case '4':
          case '13':
            return 'complianceArea';
          case '5':
            return 'craArea';
        }
      };

      var areaList = [];
      response.examAreas = _.each(response.examAreas, function(area) {
        area.eic = area.eic || '';
        area.federalEIC = area.federalEIC || '';

        response[getExamAreaName(area)] = area;
        areaList.push({
          id: area.examAreaId,
          area: area.examArea
        });
      });
      response.examAreas = areaList;

      _.each(response.examConcentrations, function(concentration) {
        concentration.typeDisplay = concentration.type || '--';
        concentration.subTypeDisplay = concentration.subType || '--';
        concentration.commentsDisplay = concentration.comments || '--';
      });

      _.each(response.examViolations, function(violation) {
        violation.legalCiteDisplay = violation.legalCite || '--';
        violation.commentsDisplay = violation.comments || '--';
      });

      var dateCfg = _.extend({}, momentUtil.defaultInboundDateConfig, { default: undefined });
      _.each(response.examProcessingResponses, function(response) {
        response.responseDueDate = momentUtil.formatStr(response.responseDueDate, dateCfg);
        response.responseReceivedDate = momentUtil.formatStr(response.responseReceivedDate, dateCfg);
        response.acknowledgementSentDate = momentUtil.formatStr(response.acknowledgementSentDate, dateCfg);
      });

      _.each(response.examParticipatingAgencies, function(agency) {
        agency.nameDisplay = agency.nameDisplay || '--';
        agency.regionDisplay = agency.regionDisplay || '--';
        agency.otherRegionDisplay = agency.otherRegion || '--';
      });

      _.each(response.examPriorExams, function(exam) {
        exam.leadAgencyDisplay = exam.leadAgency || '--';
        exam.otherLeadAgencyDisplay = exam.otherLeadAgency || '--';
        exam.ratingDisplay = exam.rating || '--';
      });

      momentUtil.formatDateAttributes(
        response,
        [
          'asOfDate',
          'bhcAsOfDate',
          'TSPAsOfDate',
          'examPriorExams.priorExamDate',
          'dueDate',
          'initialProcessorReceivedDate',
          'sentToCaseManagerDate',
          'sentToFinalReviewerDate',
          'stateLeadAgencySentToFedDate',
          'stateLeadAgencyReceivedFromFedDate',
          'leadAgencyReceivedFromFedDate',
          'leadAgencyReturnedToFedDate',
          'saerCompleteDate',
          'completionDate',
          'finalProcessorReceivedDate',
          'finalProcessorSentForTransmittalDate',
          'signoffSSDate',
          'signoffITDate',
          'signoffTrustDate',
          'transmittalDate',
          'receivedByEntityDate',
          'examClosedDate',
          'ssArea.firstDayLetterDate',
          'ssArea.asOfDate',
          'ssArea.examStartDate',
          'ssArea.exitMeetingDate',
          'ssArea.firstDayLetterAcknowledgedDate',
          'trustArea.firstDayLetterDate',
          'trustArea.asOfDate',
          'trustArea.examStartDate',
          'trustArea.exitMeetingDate',
          'trustArea.firstDayLetterAcknowledgedDate',
          'itArea.firstDayLetterDate',
          'itArea.asOfDate',
          'itArea.examStartDate',
          'itArea.exitMeetingDate',
          'itArea.firstDayLetterAcknowledgedDate',
          'complianceArea.asOfDate',
          'craArea.asOfDate'
        ],
        momentUtil.defaultInboundDateConfig.sourceFormat,
        momentUtil.defaultInboundDateConfig.targetFormat,
        ''
      );

      if (response.agencyAgreementId === '0') {
        response.agencyAgreement = 'No';
      } else if (response.agencyAgreementId === '1') {
        response.agencyAgreement = 'Yes';
      }

      if (response.isHoldingCompanyExam === '0') {
        response.isHoldingCompanyExamDisplay = 'No';
      } else if (response.isHoldingCompanyExam === '1') {
        response.isHoldingCompanyExamDisplay = 'Yes';
      }

      var examOwner = response.examOwner;
      response.examOwner.displayName = buildExternalUserDisplay(
        examOwner.userId,
        examOwner.firstName,
        examOwner.lastName
      );

      response.actionComments = response.actionComments || '--';

      var decimals = 0;
      if (response.ssArea && response.ssArea.assetSize) {
        response.ssArea.assetSizeDisplay = formatAsDollars(parseFloat(response.ssArea.assetSize), decimals);
        response.ssArea.assetSize = parseInt(response.ssArea.assetSize);
      }

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
