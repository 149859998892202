/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var NProgress = require('nprogress');
var Backbone = require('backbone');

var NavUtils = require('psa-core/util/nav');

var Scaffold = require('common/layout/Scaffold');

var GetComplaint = require('../../complaint-dashboard/services/GetComplaint');
var GetComplaintLitigationHoldStatus = require('../../complaint-dashboard/services/GetComplaintLitigationHoldStatus');

var ConSerComplaintEditLitigationHoldView = require('../views/ConSerComplaintEditLitigationHoldView');

module.exports = function(complaintId) {
  NProgress.start();
  NProgress.set(0.6);

  $.when(GetComplaint(complaintId), GetComplaintLitigationHoldStatus(complaintId))
    .done(function(complaint, complaintLegalHoldStatus) {
      NavUtils.navigate('conser/complaint/' + complaintId + '/edit-litigation-hold-status');
      Scaffold.content.show(
        new ConSerComplaintEditLitigationHoldView({
          model: new Backbone.Model({
            complaintId: complaintId,
            complaint: complaint,
            hasLitigationHold: complaintLegalHoldStatus.hasLitigationHold,
            litigationHoldStatusNote: complaintLegalHoldStatus.litigationHoldStatusNote
          })
        })
      );
      NProgress.done();
    })
    .fail(function() {
      NProgress.done();
    });
};
