/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

var tmplConSerEntityContactsView = require('../templates/ConSerEntityContactsView.hbs');
var ConSerEntityContactsSectionView = require('./ConSerEntityContactsSectionView');

var _ = require('underscore');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerEntityContactsView,

  className: 'con-ser-entity-contacts-view',

  ui: {
    contactsGrid: '.contacts-grid',
    includeInactiveLicensesCheckbox: '.include-inactive-licenses-checkbox'
  },

  regions: {
    contactsGrid: '@ui.contactsGrid'
  },

  events: {
    'change @ui.includeInactiveLicensesCheckbox': 'onChangeIncludeInactiveLicensesCheckbox'
  },

  onBeforeShow: function() {
    var self = this;
    self.originalCollection = this.collection.fullCollection.models;
    this.contactGrid = new ConSerEntityContactsSectionView({
      collection: self.collection,
      departmentId: 1000
    });
    var ndInactiveLicenses = ['3', '4', '6', '8', '9', '10', '11', '12', '14', '15', '16', '21'];
    var models = this.collection.fullCollection.models.filter(function(contact) {
      return !_.contains(ndInactiveLicenses, contact.attributes.licenseStatusId);
    });
    self.contactGrid.collection.fullCollection.reset(models);
    this.showChildView('contactsGrid', this.contactGrid);
  },

  onChangeIncludeInactiveLicensesCheckbox: function() {
    var isIncludeDiscChecked = this.ui.includeInactiveLicensesCheckbox.prop('checked');
    var self = this;
    if (!isIncludeDiscChecked) {
      var ndInactiveLicenses = ['3', '4', '6', '8', '9', '10', '11', '12', '14', '15', '16', '21'];
      var models = this.collection.fullCollection.models.filter(function(contact) {
        return !_.contains(ndInactiveLicenses, contact.attributes.licenseStatusId);
      });
      self.contactGrid.collection.fullCollection.reset(models);
    } else {
      self.contactGrid.collection.fullCollection.reset(self.originalCollection);
    }
  }
});
