/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var PlaceholderView = require('common/views/PlaceholderView');
var _ = require('underscore');

var GetContactsByEntityAndDepartment = require('../../common/services/GetContactsByEntityAndDepartment');
var GetDPCSContactsByEntity = require('../../common/services/GetDPCSContactsByEntity');
var GetNDCSContactsByEntity = require('../../common/services/GetNDCSContactsByEntity');
var ConSerComplaintContactCollectionView = require('../views/ConSerComplaintContactCollectionView');

module.exports = {
  channel: 'cs',

  event: 'build:contact-information-display',

  builder: function(entityData, contact) {
    return new PlaceholderView({
      viewPromise: buildContactsDropdown(entityData, contact)
    });
  }
};

function buildContactsDropdown(entityData, contactList) {
  var deferred = $.Deferred();
  // Fetch contacts for this complaint
  var contactListIds = _.map(contactList, function(contact) {
    return contact.contactID;
  });

  if (entityData.departmentId === '1000') {
    GetNDCSContactsByEntity(entityData.entityId)
      .done(function(contacts) {
        contacts = _.filter(contacts, function(contact) {
          return contact.licenseTypeId === entityData.entityType2Id || contact.isEdit === '1';
        });
        var contactCollection = new Backbone.Collection(
          _.filter(contacts, function(contact) {
            return _.contains(contactListIds, contact.contactId);
          }).map(function(contact) {
            contact.contacts = contacts;
            return contact;
          })
        );
        deferred.resolve(
          new ConSerComplaintContactCollectionView({
            collection: contactCollection.length ? contactCollection : new Backbone.Collection({ contacts: contacts })
          })
        );
      })
      .fail(function(err) {
        deferred.reject(err);
      });
    return deferred.promise();
  } else if (entityData.departmentId === '2000') {
    GetDPCSContactsByEntity(entityData.entityId)
      .done(function(contacts) {
        var contactCollection = new Backbone.Collection(
          _.filter(contacts, function(contact) {
            return _.contains(contactListIds, contact.contactId);
          }).map(function(contact) {
            contact.contacts = contacts;
            return contact;
          })
        );
        deferred.resolve(
          new ConSerComplaintContactCollectionView({
            collection: contactCollection.length ? contactCollection : new Backbone.Collection({ contacts: contacts })
          })
        );
      })
      .fail(function(err) {
        deferred.reject(err);
      });
    return deferred.promise();
  } else {
    GetContactsByEntityAndDepartment(entityData)
      .done(function(contacts) {
        var contactCollection = new Backbone.Collection(
          _.filter(contacts, function(contact) {
            return _.contains(contactListIds, contact.contactId);
          }).map(function(contact) {
            contact.contacts = contacts;
            return contact;
          })
        );
        deferred.resolve(
          new ConSerComplaintContactCollectionView({
            collection: contactCollection.length ? contactCollection : new Backbone.Collection({ contacts: contacts })
          })
        );
      })
      .fail(function(err) {
        deferred.reject(err);
      });
    return deferred.promise();
  }
}
