/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var NonDepEntityLocationCollectionView = require('./NonDepEntityLocationCollectionView');

var tmplNonDepEntitySectionLocationWrapperView = require('../templates/NonDepEntitySectionLocationView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntitySectionLocationWrapperView,

  className: 'nondep-entity-location-wrapper-view',

  ui: {
    locationsContainer: '.locations-container',
    showAllLocations: ' .show-all-locations'
  },

  regions: {
    locationsContainer: '@ui.locationsContainer'
  },

  events: {
    'click @ui.showAllLocations': 'onClickShowAllLocations'
  },

  // Filters locations to include only those with licenses or marked as headquarters.
  filterLocations: function(locations) {
    return _.filter(locations, function(location) {
      return location.licenses.length > 0 || location.isPrimaryPlaceOfBusiness;
    });
  },

  onBeforeShow: function() {
    var locations = this.model.get('locations');
    var filteredLocations = this.filterLocations(locations);

    this.entityLocationCollectionView = new NonDepEntityLocationCollectionView({
      viewMode: this.options.viewMode,
      collection: new Backbone.Collection(filteredLocations),
      model: new Backbone.Model({ entityId: this.model.get('entityId') })
    });

    this.showChildView('locationsContainer', this.entityLocationCollectionView);
  },

  onClickShowAllLocations: function(e) {
    var includeAll = this.ui.showAllLocations.prop('checked');
    var locations = this.model.get('locations');
    var updatedLocations = includeAll ? locations : this.filterLocations(locations);

    this.entityLocationCollectionView.collection.reset(updatedLocations);
  }
});
