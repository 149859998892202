/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var FileUploadCollection = require('psa-backbone/models/FileUploadCollection');
var UIUtil = require('psa-core/util/ui');
var ConfirmationModalView = require('common/views/ConfirmationModalView');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadView = require('common/views/FileUploadView');
var makeUploadCollection = require('common/util/makeUploadCollection');

var SubmitExamExternalTask = require('../../services/SubmitExamExternalTask');

var tmplSecExternalExamResponseTaskView = require('./SecExternalExamResponseTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExternalExamResponseTaskView,

  className: 'sec-external-exam-response-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    examResponseForm: '.exam-response-form',
    rfiRequestDocumentContainer: '.rfi-request-document-container',
    subsequentRequestDocumentContainer: '.subsequent-request-document-container',
    replyAttachmentsContainer: '.reply-attachments-container',
    missingFile: '.missing-file',
    submit: '.submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    subsequentRequestDocumentContainer: '@ui.subsequentRequestDocumentContainer',
    rfiRequestDocumentContainer: '@ui.rfiRequestDocumentContainer',
    replyAttachmentsContainer: '@ui.replyAttachmentsContainer'
  },

  templateHelpers: function() {
    var taskTypeId = modelGet(this.model, 'task.taskType.id');
    var hasRIFRequestDoc = modelGet(this.model, 'exam.rfiRequestDocuments').length > 0;
    return {
      isSubsequentReq: taskTypeId === '30015',
      isRFIRequest: taskTypeId === '30013',
      showRIFRequestDoc: taskTypeId === '30013' && hasRIFRequestDoc
    };
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var exam = this.model.get('exam') || {};
    var taskTypeId = modelGet(this.model, 'task.taskType.id');
    var isSubsequentReq = taskTypeId === '30015';
    var rfiRequestDocuments = exam.rfiRequestDocuments;
    var showRIFRequestDoc = taskTypeId === '30013' && rfiRequestDocuments.length > 0;
    var subsequentRequestDocuments = exam.subsequentRequestDocuments;

    var headerBarColumns = [
      {
        name: 'examNumber',
        label: 'ExamNumber',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'crdNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'applicantLegalName',
        label: 'Applicant Legal Name',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(exam)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#ext/sec/dashboard',
            text: 'Home'
          },
          {
            text: modelGet(this.model, 'task.taskType.description')
          }
        ]
      })
    );

    if (showRIFRequestDoc) {
      this.rfiRequestDocuments = new FileUploadView({
        collection: new FileUploadCollection(rfiRequestDocuments),
        allowMultipleFiles: true,
        isReadOnly: true
      });
      this.showChildView('rfiRequestDocumentContainer', this.rfiRequestDocuments);
    }

    if (isSubsequentReq) {
      this.subsequentRequestDocuments = new FileUploadView({
        collection: new FileUploadCollection(subsequentRequestDocuments),
        allowMultipleFiles: true,
        isReadOnly: true
      });
      this.showChildView('subsequentRequestDocumentContainer', this.subsequentRequestDocuments);
    }

    this.replyAttachments = new FileUploadView({
      collection: makeUploadCollection([]),
      isReadOnly: false,
      allowMultipleFiles: true,
      metadata: {
        documentTypeId: 30008
      }
    });
    this.showChildView('replyAttachmentsContainer', this.replyAttachments);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    this.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      UIUtil.showModal({
        title: 'Submit Notification',
        view: new ConfirmationModalView({
          confirmText: 'Submit',
          model: new Backbone.Model({
            message:
              'Do not submit until you have downloaded and saved the attachments. Once this notification is submitted, access to the notification and its attachments will be revoked.',
            onConfirm: function() {
              return SubmitExamExternalTask(self.getFormData());
            },
            onConfirmDone: function() {
              Radio.channel('navigate').trigger('show:ext:sec:dashboard');
            }
          })
        })
      });
      // l.start();
      // SubmitExamExternalTask(self.getFormData())
      //   .done(function() {
      //     Radio.channel('navigate').trigger('show:ext:sec:dashboard');
      //   })
      //   .fail(function() {
      //     l.stop();
      //   });
    });
  },

  validate: function() {
    this.ui.examResponseForm.parsley().reset();
    this.replyAttachments.removeValidationErrors();

    return $.when.apply($, [
      this.ui.examResponseForm.parsley().whenValidate(),
      this.validateReplyCommentsAttachments()
    ]);
  },

  validateReplyCommentsAttachments: function() {
    var defer = $.Deferred();

    var formData = this.getFormData();
    var hasNoComment = formData.replyComments.length === 0;
    var hasNoAttachment = formData.documents.length === 0;

    if (hasNoComment && hasNoAttachment) {
      this.ui.missingFile.show();
      defer.reject();
    } else {
      this.ui.missingFile.hide();
      defer.resolve();
    }

    return defer.promise();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.examId = this.model.get('examId');
    formData.taskId = this.model.get('taskId');

    formData.documents = this.replyAttachments.collection.toJSON();

    formData.taskType = modelGet(this.model, 'task.taskType.name');

    return formData;
  }
});
