/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var FileUploadTableView = require('common/views/FileUploadTableView');
var FlaggedLabelView = require('common/views/FlaggedLabelView');

var SubmitReviewCorrespondenceTask = require('../../services/SubmitReviewCorrespondenceTask');

var tmplReviewCorrespondenceTaskView = require('./ReviewCorrespondenceTaskView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplReviewCorrespondenceTaskView,

  className: 'non-dep-review-correspondence-task-view container-fluid',

  ui: {
    headerBar: '.header-bar',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form',
    extFileUploadTableContainer: '.ext-file-upload-table-container',
    intFileUploadTableContainer: '.int-file-upload-table-container',
    submit: '.submit',
    flaggedLabelContainer: '.flagged-label-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    extFileUploadTableContainer: '@ui.extFileUploadTableContainer',
    intFileUploadTableContainer: '@ui.intFileUploadTableContainer',
    flaggedLabelContainer: '@ui.flaggedLabelContainer'
  },

  behaviors: {
    TaskBehavior: {}
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var correspondence = this.model.get('correspondence') || {};
    var intDocuments = modelGet(this.model, 'correspondence.intDocuments', []);
    var extDocuments = modelGet(this.model, 'correspondence.extDocuments', []);
    var entityIsFlagged = this.model.get('entityIsFlagged') || {};

    var headerBarColumns = [
      {
        name: 'entityName',
        label: 'Entity Name',
        minWidth: '160px',
        maxWidth: '300px'
      },
      {
        name: 'tin',
        label: 'Federal ID Number',
        minWidth: '160px',
        maxWidth: '300px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(correspondence)
      })
    );

    if (entityIsFlagged) {
      this.showChildView(
        'flaggedLabelContainer',
        new FlaggedLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            text: 'Review to Correspondence'
          }
        ]
      })
    );

    Syphon.deserialize(this, correspondence);
    this.intFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(intDocuments),
      emptyText: 'No Internal Documents',
      isReadOnly: true
    });

    this.showChildView('intFileUploadTableContainer', this.intFileUploadTableView);

    this.extFileUploadTableView = new FileUploadTableView({
      collection: new Backbone.Collection(extDocuments),
      emptyText: 'No External Documents',
      isReadOnly: true
    });

    this.showChildView('extFileUploadTableContainer', this.extFileUploadTableView);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var l = Ladda.create(this.ui.submit[0]);
    l.start();
    SubmitReviewCorrespondenceTask({ taskId: this.model.get('taskId') })
      .done(function() {
        Radio.channel('navigate').trigger('show:int:nd:dashboard');
      })
      .fail(function() {
        l.stop();
      });
  }
});
