/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');
var Ladda = require('ladda');

var modelGet = require('common/util/modelGet');

var BreadcrumbView = require('common/views/BreadcrumbView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');
var NonDepInitiateCorrespondenceFormView = require('../../correspondence/views/NonDepInitiateCorrespondenceFormView');
var FlaggedLabelView = require('common/views/FlaggedLabelView');

var SubmitNewCorrespondence = require('../../common/services/SubmitNewCorrespondence');

var tmplNonDepLicenseInitiateCorrespondenceView = require('../templates/NonDepLicenseInitiateCorrespondenceView.hbs');
module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseInitiateCorrespondenceView,

  className: 'non-dep-license-initiate-correspondence-view container-fluid',

  ui: {
    nmlsLabel: '.nmls-label-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    correspondenceForm: '.correspondence-form-container',
    flaggedLabelContainer: '.flagged-label-container',
    submit: '.btn-submit'
  },

  regions: {
    nmlsLabel: '@ui.nmlsLabel',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    correspondenceForm: '@ui.correspondenceForm',
    flaggedLabelContainer: '@ui.flaggedLabelContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = modelGet(this.model, 'licenseIdentifiers.entityName');
    var licenseId = this.model.get('licenseId');
    var licenseNumber = modelGet(this.model, 'licenseIdentifiers.licenseNumber');
    var licenseType = modelGet(this.model, 'licenseIdentifiers.licenseType');
    var hasFlagged = this.model.get('hasFlagged');

    this.showChildView(
      'headerBar',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('licenseIdentifiers'))
      })
    );

    if (hasFlagged) {
      this.showChildView(
        'flaggedLabelContainer',
        new FlaggedLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#nondep/dashboard',
            text: 'Home'
          },
          {
            link: '#nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            link: '#nondep/entity/' + entityId + '/license/' + licenseId,
            text: 'License #' + licenseNumber + ' - ' + licenseType
          },
          {
            text: 'Initiate Correspondence'
          }
        ]
      })
    );

    this.correspondenceForm = new NonDepInitiateCorrespondenceFormView({
      model: this.model
    });

    this.showChildView('correspondenceForm', this.correspondenceForm);
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var self = this;
    var entityId = this.model.get('entityId');
    var licenseId = this.model.get('licenseId');

    this.correspondenceForm.validate().done(function() {
      var l = Ladda.create(self.ui.submit[0]);
      l.start();
      var formData = self.correspondenceForm.getFormData();
      formData.objectId = licenseId;
      formData.objectTypeId = 'LICENSE';
      formData.entityId = entityId;
      formData.isInternal = '1';

      SubmitNewCorrespondence(formData)
        .done(function() {
          Radio.channel('navigate').trigger('show:int:nd:license-dashboard', entityId, licenseId);
        })
        .fail(function() {
          l.stop();
        });
    });
  }
});
