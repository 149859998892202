var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"label label-danger label-litigation-hold-status\">\r\n            <i class=\"fa fa-lock m-r-xs\"></i> Litigation Hold Active\r\n          </span>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"label label-success label-litigation-hold-status\">\r\n            <i class=\"fa fa-unlock m-r-xs\"></i> No Litigation Hold Active\r\n          </span>\r\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <p class=\"input-label\">By checking the box below, and submitting, this complaint's litigation hold will be\r\n            removed.\r\n          </p>\r\n          <div class=\"checkbox\">\r\n            <label>\r\n              <input type=\"checkbox\" name=\"removeLitigationHold\" data-parsley-required=\"true\">\r\n              Remove Litigation Hold from <b>Complaint - "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1), depth0))
    + "</b>\r\n            </label>\r\n          </div>\r\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <p class=\"input-label\">By checking the box below, and submitting, this complaint will have an active\r\n            litigation hold.\r\n          </p>\r\n          <div class=\"checkbox\">\r\n            <label>\r\n              <input type=\"checkbox\" name=\"placeLitigationHold\" data-parsley-required=\"true\">\r\n              Place Litigation Hold on <b>Complaint - "
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1), depth0))
    + "</b>\r\n            </label>\r\n          </div>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">Complaint - "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1), depth0))
    + "</h1>\r\n  <div class=\"dropdown-container pull-right\"></div>\r\n</div>\r\n<div class=\"header-bar-container\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n\r\n<div class=\"col-sm-9 col-sm-offset-1\">\r\n  <div class=\"form-horizontal\">\r\n    <form class=\"litigation-hold-form\">\r\n      <div class=\"row\">\r\n        <h3 class=\"m-t-n col-sm-offset-3 col-sm-9\">Edit Litigation Hold Status</h3>\r\n      </div>\r\n\r\n      <div class=\"form-group\">\r\n        <label class=\"control-label col-sm-3\">Current Litigation Hold Status</label>\r\n        <div class=\"form-control-static col-sm-9\">\r\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.hasLitigationHold : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\r\n      </div>\r\n\r\n      <div class=\"form-group legal-hold-status required\">\r\n        <label class=\"control-label col-sm-3\">New Litigation Hold Status</label>\r\n        <div class=\"col-sm-9\">\r\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.hasLitigationHold : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\r\n      </div>\r\n\r\n      <div class=\"form-group required row litigation-hold-notes-container\">\r\n        <label class=\"control-label col-sm-3\">Litigation Hold Notes</label>\r\n        <div class=\"col-sm-9\">\r\n          <textarea name=\"litigationHoldNotes\" class=\"form-control\"\r\n            placeholder=\"Explain changes to the complaint litigation hold status.\" rows=\"6\" data-parsley-required=\"true\"\r\n            data-parsley-group='[\"returning\"]'></textarea>\r\n        </div>\r\n      </div>\r\n\r\n    </form>\r\n\r\n    <div class=\"row m-b-lg\">\r\n      <div class=\"col-sm-6 col-sm-offset-3 pull-left\">\r\n        <a href=\"#conser/complaint/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.complaintId : stack1), depth0))
    + "/dashboard\" class=\"btn btn-default\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n      </div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});