/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Marionette = require('backbone.marionette');

var EmptySetView = require('common/views/EmptySetView');

var SecExamStaffAssignedView = require('./SecExamStaffAssignedView');

module.exports = Marionette.CollectionView.extend({
  className: 'sec-exam-staff-assigned-collection-view',

  childView: SecExamStaffAssignedView,

  childViewOptions: function(model, index) {
    // Pass the index to the collection view
    return {
      childIndex: index,
      staffMembers: this.model.get('staffMembers'),
      examInvInqStaffFunctions: this.model.get('examInvInqStaffFunctions')
    };
  },

  childEvents: {
    'remove:staff-assigned': 'onChildClickRemoveStaffAssigned'
  },

  emptyView: EmptySetView,

  onBeforeShow: function() {},

  onChildClickRemoveStaffAssigned: function(childView) {
    var deletedRecord = childView.model;
    this.collection.remove(deletedRecord);
  },

  getFormData: function() {
    var formData = this.children.reduce(function(memo, child) {
      if (child.getFormData && _.isFunction(child.getFormData)) {
        memo.push(child.getFormData());
      }
      return memo;
    }, []);
    return formData;
  }
});
