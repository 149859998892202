/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');
var momentUtil = require('common/util/momentUtil');

module.exports = function(searchByCaseManager) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetComplaintByCaseManager: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        SearchByCaseManager: +searchByCaseManager
      }
    },
    blueprintArrays: ['GetComplaintByCaseManagerResponse.CS_Complaint'],
    blueprint: {
      complaints: [
        '$..CS_Complaint',
        {
          complaintId: '$..ID',
          caseNumber: '$..CaseNumber',
          caseManagerId: '$..CaseManager',
          entityName: '$..EntityName',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          maxOpenedDate: '$..MaxOpenedDate',
          complainantFirstName: '$..ComplainantFirstName',
          complainantLastName: '$..ComplainantLastName',
          departmentId: '$..DepartmentID',
          department: '$..Description',
          entityType2Id: '$..EntityType2ID',
          weight: '$..Weight',
          weightId: '$..WeightID',
          status: '$..Status',
          statusId: '$..StatusID',
          reminderDate: '$..ReminderDate',
          hasLitigationHold: '$..HasLitigationHold'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      _.each(response.complaints, function(complaint) {
        // build complaint individual full name
        if (complaint.firstName || complaint.lastName) {
          complaint.individualName = complaint.firstName + ' ' + complaint.lastName;
        }

        // build complainant full name
        if (complaint.complainantFirstName || complaint.complainantLastName) {
          complaint.complainantName = complaint.complainantFirstName + ' ' + complaint.complainantLastName;
        }

        if (complaint.maxOpenedDate) {
          complaint.dateOpened = moment(complaint.maxOpenedDate, momentUtil.PERSIST_DATE_FMT);
        }

        if (complaint.reminderDate) {
          complaint.reminderPastDue = moment(complaint.reminderDate).isSameOrBefore(moment());
        } else {
          complaint.reminderPastDue = false;
        }

        complaint.hasLitigationHold = complaint.hasLitigationHold === '1' ? 'Hold' : '';
      });

      deferred.resolve(response.complaints);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
