/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');

require('./SideNavLinkView.css');
var tmplSideNavLinkView = require('./SideNavLinkView.hbs');

module.exports = Marionette.ItemView.extend({
  template: tmplSideNavLinkView,

  tagName: 'li',

  attributes: {
    role: 'presentation'
  },

  ui: {
    link: 'a'
  },

  modelEvents: {
    'has:errors': 'onModelHasErrors',
    'no:errors': 'onModelNoErrors'
  },

  events: {
    'click @ui.link': 'onClickLink'
  },

  className: function() {
    var baseClass = 'side-nav-link-view';
    if (this.options.isLinkActive) {
      return baseClass + ' active';
    }
    return baseClass;
  },

  templateHelpers: function() {
    return {
      childIndex: this.options.childIndex
    };
  },

  onModelHasErrors: function() {
    this.ui.link.addClass('has-errors');
  },

  onModelNoErrors: function() {
    this.ui.link.removeClass('has-errors');
  },

  onClickLink: function() {
    this.ui.link.tab('show');
    this.triggerMethod('link:clicked', this.options.childIndex);
  },

  isActive: function() {
    return this.$el.hasClass('active');
  },

  hasErrors: function() {
    return this.ui.link.hasClass('has-errors');
  },

  makeActive: function() {
    this.ui.link.tab('show');
  }
});
