/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');

var modelGet = require('common/util/modelGet');
var momentUtil = require('common/util/momentUtil');
var SideNavSectionView = require('common/views/SideNavSectionView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var DropdownView = require('common/views/DropdownView');
var LitigationHoldLabelView = require('common/views/LitigationHoldLabelView');
var userHasRole = require('common/util/userHasRole');

var ConSerComplaintSectionInformationView = require('./ConSerComplaintSectionInformationView');
var ConSerComplaintSectionCodesView = require('./ConSerComplaintSectionCodesView');
var ConSerComplaintSectionComplainantView = require('./ConSerComplaintSectionComplainantView');
var ConSerComplaintSectionEntityIndividualView = require('./ConSerComplaintSectionEntityIndividualView');
var ConSerComplaintSectionContactView = require('./ConserComplaintSectionContactView');

var SubmitComplaint = require('../services/SubmitComplaint');

var tmplConSerComplaintDashboardView = require('../templates/ConSerComplaintDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintDashboardView,

  className: 'con-ser-complaint-dashboard-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    complaintActionDropDown: '.complaint-action-drop-down',
    litigationHoldLabelContainer: '.litigation-hold-label-container',
    complaintDashboard: '.complaint-dashboard-container',
    csLinkContainer: '.cs-link-container',
    save: '.save'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    complaintActionDropDown: '@ui.complaintActionDropDown',
    litigationHoldLabelContainer: '@ui.litigationHoldLabelContainer',
    complaintDashboard: '@ui.complaintDashboard',
    csLinkContainer: '@ui.csLinkContainer'
  },

  onBeforeShow: function() {
    var isLitigationHoldManager = userHasRole('CS - Litigation Hold');
    var complaint = this.model.get('complaint') || {};
    var hasLitigationHold = this.model.get('hasLitigationHold');

    if (complaint.complaintId) {
      this.showChildView(
        'csLinkContainer',
        new ContentServerLinkView({
          model: new Backbone.Model({
            buttonText: 'Attachments',
            contentServerId: complaint.CSFolderId
          })
        })
      );
    }

    var complaintBreadcrumbText = '';
    if (!complaint.caseNumber) {
      complaintBreadcrumbText = 'New Complaint';
    } else {
      if (complaint.complaintTypeId === '2') {
        complaintBreadcrumbText = 'Investment Complaint - ' + complaint.caseNumber;
      } else {
        complaintBreadcrumbText = 'Complaint - ' + complaint.caseNumber;
      }
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            text: complaintBreadcrumbText
          }
        ]
      })
    );

    var sideNavTabs = [
      {
        id: 'complaintInformation',
        title: 'Complaint Information',
        view: new ConSerComplaintSectionInformationView({
          model: this.model
        })
      },
      {
        id: 'complainant',
        title: 'Consumer',
        view: new ConSerComplaintSectionComplainantView({ model: this.model })
      },
      {
        id: 'complaintCodes',
        title: 'Complaint Codes',
        view: new ConSerComplaintSectionCodesView({ model: this.model })
      },
      {
        id: 'entityIndividualInformation',
        title: 'Entity/Individual Information',
        view: new ConSerComplaintSectionEntityIndividualView({ model: this.model })
      },
      {
        id: 'contactInformation',
        title: 'Contact Information',
        view: new ConSerComplaintSectionContactView({ model: this.model })
      },
      {
        id: 'relatedComplaints',
        title: 'Related Complaints',
        view: Radio.channel('cs').request('build:related-complaints-section-view', complaint.complaintId)
      }
    ];

    if (complaint.complaintId) {
      sideNavTabs = sideNavTabs.concat([
        {
          id: 'correspondence',
          title: 'Correspondence',
          view: Radio.channel('cs').request('build:complaint-correspondence-view', complaint.complaintId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('cs').request('build:notes-view', complaint.complaintId)
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('cs').request('build:audit-log-view', complaint.complaintId)
        }
      ]);
    }

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs),
      useNavigationButtons: true,
      buttons: [
        {
          buttonText: 'Save',
          buttonClass: 'btn-default ladda-button save',
          buttonIconClass: 'fa-save',
          labelClass: 'ladda-label',
          buttonAttrs: {
            'data-style': 'expand-right'
          },
          onClick: this.onClickSave.bind(this)
        }
      ]
    });

    if (complaint.complaintId) {
      var dropDownOptions = [];

      if (isLitigationHoldManager) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Edit Litigation Hold Status',
            link: '#conser/complaint/' + complaint.complaintId + '/edit-litigation-hold-status'
          }
        ]);
      }

      this.showChildView(
        'complaintActionDropDown',
        new DropdownView({
          rightAlign: true,
          model: new Backbone.Model({
            name: 'Actions',
            options: dropDownOptions
          })
        })
      );

      if (hasLitigationHold) {
        this.showChildView(
          'litigationHoldLabelContainer',
          new LitigationHoldLabelView({
            model: new Backbone.Model({
              objectName: 'complaint'
            })
          })
        );
      }
    }

    this.showChildView('complaintDashboard', this.dashboard);
  },

  onClickSave: function() {
    var self = this;
    this.dashboard.validateAll().done(function() {
      var data = self.dashboard.getFormData();

      var formData = self.formatData(data);

      var l = Ladda.create(self.$('.save')[0]);
      l.start();

      SubmitComplaint(formData)
        .done(function(complaintId) {
          Radio.channel('navigate').trigger('show:int:cs:complaint', complaintId);
        })
        .fail(function() {
          l.stop();
        });
    });
  },

  formatData: function(data) {
    var formData = _.extend(
      data.complaintInformation,
      data.complainant,
      data.complaintCodes,
      data.entityIndividualInformation,
      data.contactInformation
    );

    formData.refundDate = momentUtil.formatStr(formData.refundDate, momentUtil.defaultOutboundDateConfig);
    formData.fundedLoanDate = momentUtil.formatStr(formData.fundedLoanDate, momentUtil.defaultOutboundDateConfig);

    _.each(formData.openCloseHistory, function(history) {
      history.openedDate = momentUtil.formatStr(history.openedDate, momentUtil.defaultOutboundDateConfig);
      history.closedDate = momentUtil.formatStr(history.closedDate, momentUtil.defaultOutboundDateConfig);
    });

    if (formData.openCloseHistory.length) {
      formData.initialOpenedDate = formData.openCloseHistory[0].openedDate;
      formData.finalClosedDate = formData.openCloseHistory[formData.openCloseHistory.length - 1].closedDate;
    }
    formData.complaintId = modelGet(this.model, 'complaint.complaintId');

    return formData;
  }
});
