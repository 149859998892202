/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var momentUtil = require('common/util/momentUtil');

var tmplSecExamStaffAssignedView = require('../templates/SecExamStaffAssignedView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamStaffAssignedView,

  className: 'sec-exam-staff-assigned-view panel panel-info',

  ui: {
    removeStaffAssigned: '.remove-staff-assigned',
    datePickerField: '.date-picker'
  },

  triggers: {
    'click @ui.removeStaffAssigned': 'remove:staff-assigned'
  },

  templateHelpers: function() {
    var staffMembers = this.options.staffMembers;
    var examInvInqStaffFunctions = this.options.examInvInqStaffFunctions;

    return {
      childIndex: this.options.childIndex + 1,
      staffMembers: staffMembers,
      examInvInqStaffFunctions: examInvInqStaffFunctions
    };
  },

  onBeforeShow: function() {
    this.ui.datePickerField.datepicker({});
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.id = this.model.get('id');
    formData.assignedDate = momentUtil.formatStr(formData.assignedDate, momentUtil.defaultOutboundDateConfig);
    formData.removedDate = momentUtil.formatStr(formData.removedDate, momentUtil.defaultOutboundDateConfig);

    return formData;
  }
});
