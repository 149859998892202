/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var PageableCollection = require('psa-backbone/models/PageableCollection');

var ContentServerLinkView = require('common/views/ContentServerLinkView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var DropdownView = require('common/views/DropdownView');
var BreadcrumbView = require('common/views/BreadcrumbView');
var EmptyView = require('common/views/EmptyView');
var LegalHoldLabelView = require('common/views/LegalHoldLabelView');
var FlaggedLabelView = require('common/views/FlaggedLabelView');
var userHasRole = require('common/util/userHasRole');
var modelGet = require('common/util/modelGet');

var EntityHeaderBarView = require('../../common/views/EntityHeaderBarView');
var NMLSLabelView = require('../../common/views/NMLSLabelView');

var NonDepEntityExternalPendingTaskView = require('./NonDepEntityExternalPendingTaskView');
var NonDepEntityInternalPendingTaskView = require('./NonDepEntityInternalPendingTaskView');
var NonDepEntitySectionIdentificationView = require('./NonDepEntitySectionIdentificationView');
var NonDepEntitySectionAddDBAView = require('./NonDepEntitySectionAddDBAView');

var tmplNonDepEntityDashboardView = require('../templates/NonDepEntityDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepEntityDashboardView,

  className: 'nondep-entity-dashboard-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    legalHoldLabelContainer: '.legal-hold-label-container',
    flaggedLabelContainer: '.flagged-label-container',
    entityActionDropDown: '.dropdown-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    entityDashboard: '.entity-dashboard-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    legalHoldLabelContainer: '@ui.legalHoldLabelContainer',
    flaggedLabelContainer: '@ui.flaggedLabelContainer',
    entityActionDropDown: '@ui.entityActionDropDown',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    entityDashboard: '@ui.entityDashboard'
  },

  onBeforeShow: function() {
    var self = this;
    var entity = this.model.get('entity') || {};
    var hasLegalHold = this.model.get('hasLegalHold');
    var hasFlagged = this.model.get('hasFlagged');
    var entityId = entity.id;
    var viewMode = this.options.viewMode;
    // var isManager = userHasRole('ND - Manager');
    var isFlagManager = userHasRole('ND - Flag Manager');
    var userAccessLevel = modelGet(this.model, 'userAccessLevel', {});
    var isVerified = entity.isVerified;
    var externalManagerAccessLevelId = 20;
    var isLicenseOffice = userHasRole('ND - Licensing Office');
    var isComplianceOffice = userHasRole('ND - Compliance Office');

    if (entity.isNMLSEntity) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    if (hasLegalHold && viewMode === 'int') {
      this.showChildView(
        'legalHoldLabelContainer',
        new LegalHoldLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    if (hasFlagged && viewMode === 'int') {
      this.showChildView(
        'flaggedLabelContainer',
        new FlaggedLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    var dropDownOptions = [];
    if (viewMode === 'ext') {
      if (userAccessLevel.accessLevelId >= externalManagerAccessLevelId) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Apply for a License',
            link: '#ext/nondep/entity/' + entityId + '/license-app/new'
          }
        ]);
        if (!entity.isNMLSEntity && isVerified) {
          dropDownOptions = dropDownOptions.concat([
            {
              isDivider: true
            },
            {
              text: 'Apply to Edit Identification Information',
              link: '#ext/nondep/entity/' + entityId + '/change-name'
            },
            {
              text: 'Apply to Add An Officer',
              link: '#ext/nondep/entity/' + entityId + '/new-officer'
            },
            {
              text: 'Apply to Edit Books and Records Locations',
              link: '#ext/nondep/entity/' + entityId + '/records-location-change'
            }
          ]);
        } else if (!entity.isNMLSEntity && !isVerified) {
          dropDownOptions = dropDownOptions.concat([
            {
              isDivider: true
            },
            {
              text: 'Edit Entity',
              link: '#ext/nondep/entity/' + entityId + '/edit'
            }
          ]);
        }

        dropDownOptions = dropDownOptions.concat([
          {
            isDivider: true
          },
          {
            text: 'Add Location',
            link: '#ext/nondep/entity/' + entityId + '/location/new'
          },
          {
            isDivider: true
          },
          {
            text: 'Initiate Correspondence',
            link: '#ext/nondep/entity/' + entityId + '/initiate-correspondence'
          }
        ]);
      } else {
        this.ui.entityActionDropDown.hide();
      }
    } else if (viewMode === 'int') {
      if (isLicenseOffice) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Edit Identification',
            link: '#nondep/entity/' + entityId + '/edit-identification'
          },
          {
            text: 'Edit Books and Records Locations',
            link: '#nondep/entity/' + entityId + '/edit-books-and-records'
          },
          {
            text: 'Edit RICS Information',
            link: '#nondep/entity/' + entityId + '/edit-rics-compliance'
          },
          {
            text: 'Edit Legal Hold Status',
            link: '#nondep/entity/' + entityId + '/edit-legal-hold-status'
          },
          {
            isDivider: true
          },
          {
            text: 'Add Entity Officer',
            link: '#nondep/entity/' + entityId + '/add-officer'
          },
          {
            text: 'Add Location',
            link: '#nondep/entity/' + entityId + '/location/new'
          },
          {
            isDivider: true
          },
          {
            text: 'Create New License',
            link: '#nondep/entity/' + entityId + '/new-license'
          },
          // {
          //   text: 'Create Compliance Request',
          //   link: '#nondep/entity/' + entityId + '/new-compliance'
          // },
          {
            isDivider: true
          }
        ]);
      }
      if (isComplianceOffice) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Create Compliance Request',
            link: '#nondep/entity/' + entityId + '/new-compliance'
          }
        ]);
      }
      if (isFlagManager) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Edit Flagged Status',
            link: '#nondep/entity/' + entityId + '/edit-flagged-status'
          }
        ]);
      }

      dropDownOptions = dropDownOptions.concat([
        {
          text: 'Initiate Correspondence',
          link: '#nondep/entity/' + entityId + '/initiate-correspondence'
        },
        {
          isDivider: true
        },
        {
          text: 'View Entity History',
          link: '#nondep/entity/' + entityId + '/history'
        }
        //  {
        //This is temporary until the Sync is corrected.
        //    text: 'Add Entity Officer',
        //    link: '#nondep/entity/' + entityId + '/add-officer'
        //  }
      ]);
    }

    this.showChildView(
      'entityActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'Entity Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'headerBar',
      new EntityHeaderBarView({
        model: new Backbone.Model(this.model.get('entityIdentifiers'))
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: this.options.viewMode === 'int' ? '#nondep/dashboard' : '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            text: entity.entityName
          }
        ]
      })
    );

    var sideNavTabs = [
      {
        id: 'pendingTasks',
        title: 'Pending Tasks',
        view: new ((function() {
          if (self.options.viewMode === 'int') {
            return NonDepEntityInternalPendingTaskView;
          }
          if (self.options.viewMode === 'ext') {
            return NonDepEntityExternalPendingTaskView;
          }
          return EmptyView;
        })())({
          viewMode: this.options.viewMode,
          userAccessLevel: userAccessLevel,
          collection: new PageableCollection(this.model.get('tasks'), { pageSize: 15 })
        })
      },
      {
        id: 'activeApplications',
        title: 'Active Applications',
        view: Radio.channel('nd').request('build:entity-active-applications-view', {
          viewMode: this.options.viewMode,
          userAccessLevel: userAccessLevel,
          entityId: entityId
        })
      },
      {
        id: 'identification',
        title: 'Identification',
        view: new NonDepEntitySectionIdentificationView({
          viewMode: this.options.viewMode,
          model: new Backbone.Model(entity)
        })
      },
      {
        id: 'addDBA',
        title: 'D/B/A',
        view: new NonDepEntitySectionAddDBAView({
          viewMode: this.options.viewMode,
          model: new Backbone.Model(entity)
        })
      },
      {
        id: 'locations',
        title: 'Locations',
        view: Radio.channel('nd').request('build:entity-section-location-view', {
          viewMode: this.options.viewMode,
          entityId: entityId
        })
      },
      {
        id: 'entityOfficers',
        title: 'Entity Officers',
        view: Radio.channel('nd').request('build:entity-officers-view', {
          viewMode: this.options.viewMode,
          entityId: entityId,
          userAccessLevel: userAccessLevel,
          isNMLSEntity: entity.isNMLSEntity,
          isVerified: entity.isVerified
        })
      },
      {
        id: 'booksAndRecords',
        title: 'Books and Records',
        view: Radio.channel('nd').request('build:entity-site-record-keeping-view', {
          viewMode: this.options.viewMode,
          entityId: entityId
        })
      },
      {
        id: 'userManagement',
        title: 'User Management',
        view: Radio.channel('nd').request('build:entity-section-user-management-view', {
          viewMode: this.options.viewMode,
          userAccessLevel: userAccessLevel,
          entityId: entityId,
          entityName: entity.entityName
        })
      }
    ];

    if (this.options.viewMode === 'int') {
      this.showChildView(
        'csLinkContainer',
        new ContentServerLinkView({
          model: new Backbone.Model({
            buttonText: 'Open Entity Folder',
            contentServerId: modelGet(this.model, 'entity.CSFolderId')
          })
        })
      );

      sideNavTabs.splice(6, 0, {
        id: 'ricsCompliance',
        title: 'RICS Information',
        view: Radio.channel('nd').request('build:entity-rics-compliance-view', {
          entityId: entityId,
          tin: entity.tin
        })
      });

      sideNavTabs = sideNavTabs.concat([
        {
          id: 'compliance',
          title: 'Compliance',
          view: Radio.channel('nd').request('build:entity-compliance-grid-view', entityId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('nd').request('build:notes-view', {
            objectId: entityId,
            objectTypeId: 'ENTITY'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('nd').request('build:audit-log-view', {
            objectId: entityId,
            objectTypeId: 'ENTITY'
          })
        }
      ]);
    }

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });
    this.showChildView('entityDashboard', this.dashboard);
  }
});
