/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var Radio = require('backbone.radio');
var Ladda = require('ladda');
var moment = require('moment');

var modelGet = require('common/util/modelGet');
var toggleForm = require('common/util/toggleForm');
var FlaggedLabelView = require('common/views/FlaggedLabelView');

var SubmitManagerComplianceRequestReviewTask = require('../../services/SubmitManagerComplianceRequestReviewTask');
var ComplianceJumpToDropdownView = require('../../views/ComplianceJumpToDropdownView');

require('./ComplianceManagerReviewCaseRequestView.css');
var tmplReviewComplianceCaseRequestView = require('./ComplianceManagerReviewCaseRequestView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplReviewComplianceCaseRequestView,

  className: 'non-dep-compliance-case-request-review-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    reviewForm: '.review-form',
    jumpTo: '.jump-to-container',
    summary: '.summary-container',
    decisionSelect: '.decision-select',
    acceptInputs: '.accept-inputs',
    dateReceived: '.date-received',
    dateAssigned: '.date-assigned',
    acceptNarrative: '.accept-narrative',
    rejectReason: '.reject-reason',
    submit: '.btn-submit',
    flaggedLabelContainer: '.flagged-label-container'
  },

  regions: {
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    jumpTo: '@ui.jumpTo',
    summary: '@ui.summary',
    flaggedLabelContainer: '@ui.flaggedLabelContainer'
  },

  events: {
    'change @ui.decisionSelect': 'onChangeDecision',
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {}
  },

  templateHelpers: function() {
    return {
      currentDate: moment().format('MM/DD/YYYY')
    };
  },

  onBeforeShow: function() {
    var compliance = this.model.get('complianceDetails') || {};
    var entityIsFlagged = modelGet(this.model, 'complianceDetails.entityIsFlagged', []);

    this.showChildView('headerBar', this.options.complianceHeaderBar);

    this.showChildView('breadcrumb', this.options.complianceBreadcrumbs);

    if (entityIsFlagged) {
      this.showChildView(
        'flaggedLabelContainer',
        new FlaggedLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'jumpTo',
      new ComplianceJumpToDropdownView({
        model: new Backbone.Model({
          compliance: compliance
        })
      })
    );

    this.showChildView('summary', this.options.complianceSummary);

    this.ui.dateReceived.datepicker();

    this.ui.dateAssigned.datepicker();

    this.onChangeDecision();
  },

  onChangeDecision: function() {
    var decision = this.ui.decisionSelect.val();

    if (decision === '2101') {
      toggleForm(this.ui.acceptInputs, true);
      toggleForm(this.ui.acceptNarrative, true);
      toggleForm(this.ui.rejectReason, false);
    } else if (decision === '2102') {
      toggleForm(this.ui.acceptInputs, false);
      toggleForm(this.ui.acceptNarrative, false);
      toggleForm(this.ui.rejectReason, true);
    } else {
      toggleForm(this.ui.acceptInputs, false);
      toggleForm(this.ui.acceptNarrative, false);
      toggleForm(this.ui.rejectReason, false);
    }
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitManagerComplianceRequestReviewTask(this.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.complianceId = this.model.get('complianceDetails').complianceId;
    formData.taskId = this.model.get('task').taskId;

    formData.dateAssigned = moment(formData.dateAssigned, 'MM/DD/YYYY').format('YYYY-MM-DD');
    formData.dateReceived = moment(formData.dateReceived, 'MM/DD/YYYY').format('YYYY-MM-DD');

    return formData;
  },

  validate: function() {
    return this.ui.reviewForm.parsley().whenValidate();
  }
});
