var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"row contacttypeform-group\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-3\">Contact Type Legacy</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"contactType\" class=\"form-control\" placeholder=\"Contact Type\"\r\n                data-parsley-maxlength=\"100\" readonly />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"row\">\r\n  <div class=\"form-horizontal col-sm-12\">\r\n    <form class=\"entity-form\">\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-3\">First Name</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\"\r\n                data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-3\">Last Name</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\"\r\n                data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-3\">Title</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"title\" class=\"form-control\" placeholder=\"Title\" data-parsley-maxlength=\"100\" />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.contactType : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-3\">Contact Type</label>\r\n            <div class=\"col-sm-9\">\r\n              <select name=\"contactTypeID\" class=\"select-contact-type-id form-control\" data-parsley-required=\"true\">\r\n                <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.contactType : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </select>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-3\">Phone Number</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"phoneNumber\" class=\"form-control\" placeholder=\"Phone Number\"\r\n                data-parsley-maxlength=\"30\" />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-3\">Fax Number</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"fax\" class=\"form-control\" placeholder=\"Fax Number\" data-parsley-maxlength=\"20\" />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12\">\r\n          <div class=\"form-group\">\r\n            <label class=\"control-label col-sm-3\">Email</label>\r\n            <div class=\"col-sm-9\">\r\n              <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"254\"\r\n                data-parsley-type=\"email\" />\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-9 col-sm-offset-3\">\r\n          <h4>Address</h4>\r\n        </div>\r\n      </div>\r\n      <div class=\"address-form-container\"></div>\r\n    </form>\r\n  </div>\r\n</div>";
},"useData":true});