/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');

var toggleForm = require('common/util/toggleForm');
var modelGet = require('common/util/modelGet');

var tmplDepAuditFormView = require('../templates/DepAuditFormView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplDepAuditFormView,

  className: 'dep-audit-form-view',

  ui: {
    auditDate: '.audit-date',
    receivedDate: '.received-date',
    acknowledgedDate: '.acknowledged-date',
    approvedDate: '.approved-date',
    lastRevisedDate: '.last-revised-date',
    letterReceivedDate: '.letter-received-date',
    responseReceivedDate: '.response-received-date',
    auditTypeDropdown: 'select[name="auditTypeId"]',
    dateOfBirth: '.date-of-birth',
    trustAuditTypeArea: '.trust-audit-type-area',
    auditFirmDropdown: '.audit-firm-dropdown',
    internalAuditArea: '.internal-audit-area',
    opinion: 'select[name="opinionId"]',
    auditTrustType: 'select[name="auditTrustTypeId"]',
    opinionArea: '.opinion-area',
    opinionDetailsArea: '.opinion-details-area',
    managementLetterArea: '.management-letter-area',
    responseArea: '.response-area',
    goingConcern: '.goingConcern'
  },

  events: {
    'change @ui.auditTypeDropdown': 'onChangeForm',
    'click @ui.trustAuditTypeArea': 'onChangeForm',
    'change @ui.opinion': 'onChangeForm'
  },

  onBeforeShow: function() {
    Syphon.deserialize(this, this.model.get('audit'));

    this.onChangeForm();

    this.ui.auditDate.datepicker();
    this.ui.receivedDate.datepicker();
    this.ui.acknowledgedDate.datepicker();
    this.ui.approvedDate.datepicker();
    this.ui.lastRevisedDate.datepicker();
    this.ui.letterReceivedDate.datepicker();
    this.ui.responseReceivedDate.datepicker();
    
  },

  onChangeForm: function() {
    var categoryId = modelGet(this.model, 'entityIdentifiers.categoryId');
    var isBank = categoryId === '1';
    var isTrust = categoryId === '3';

    var formData = Syphon.serialize(this);
    var isATTApprovedInternal = formData.auditTrustTypeId === '2';

    var isAnnualAudit = formData.auditTypeId === '1';
    var isInternalProgramAudit = formData.auditTypeId === '2';
    var isTrustDeptAudit = formData.auditTypeId === '3';
    var isAuditTypeFinancialAudit = formData.auditTypeId === '21';
    var isAuditTypeTrustAudit = formData.auditTypeId === '22';
    // is external Opinion if opinion is qualified or unqualified
    var isOpinionInt = formData.opinionId === '2';

    var showTrustAuditType = isAuditTypeTrustAudit || (isBank && isTrustDeptAudit);
    toggleForm(this.ui.trustAuditTypeArea, showTrustAuditType);

    var showOpinionDetails = showTrustAuditType || isTrust;
    toggleForm(this.ui.opinionDetailsArea, showOpinionDetails);
    toggleForm(this.ui.opinionArea, !showOpinionDetails);
    toggleForm(this.ui.internalAuditArea, isATTApprovedInternal || isOpinionInt);
    toggleForm(this.ui.responseArea, isBank && isInternalProgramAudit);
    toggleForm(this.ui.managementLetterArea, false);

    if ((isBank && isAnnualAudit) || (isTrust && isAuditTypeFinancialAudit)) {
      toggleForm(this.ui.opinionArea, true);
      toggleForm(this.ui.trustAuditTypeArea, false);
      toggleForm(this.ui.opinionDetailsArea, false);
      toggleForm(this.ui.managementLetterArea, true);
      toggleForm(this.ui.responseArea, true);

    }
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.entityId = this.model.get('entityId');
    formData.auditId = this.model.get('auditId');
    return formData;
  }
});
