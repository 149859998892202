/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

module.exports = function(formData) {
  var ppob = formData.ppob;
  var mailing = formData.mailingAddress;
  var billing = formData.billingAddress;

  formData.totalAssetsAsOfDate = formData.totalAssetsAsOfDate
    ? moment(formData.totalAssetsAsOfDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
    formData.statusDate = formData.statusDate
    ? moment(formData.statusDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.totalAssetsUnderManagementAsOfDate = formData.totalAssetsUnderManagementAsOfDate
    ? moment(formData.totalAssetsUnderManagementAsOfDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.totalCapitalAsOfDate = formData.totalCapitalAsOfDate
    ? moment(formData.totalCapitalAsOfDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.FFCapitalAsOfDate = formData.FFCapitalAsOfDate
    ? moment(formData.FFCapitalAsOfDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.FFDeptActionDate = formData.FFDeptActionDate
    ? moment(formData.FFDeptActionDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.FFTerminationDate = formData.FFTerminationDate
    ? moment(formData.FFTerminationDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.charteredOnDate = formData.charteredOnDate
    ? moment(formData.charteredOnDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';
  formData.nextExamStartDate = formData.nextExamStartDate
    ? moment(formData.nextExamStartDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
    : '';

  return $.soap({
    body: {
      SubmitEntity: {
        $xmlns: 'http://dobs.pa.gov/dp/entity/bpm/1.0',
        EntityID: formData.entityId,
        EntityName: formData.entityName,
        DBAs: (function() {
          if (!formData.newDBAs || !formData.newDBAs.length) {
            return {};
          } else {
            return {
              DBA: _.map(formData.newDBAs, function(dba) {
                return {
                  DBA: dba.dbaName
                };
              })
            };
          }
        })(),
        ExistingDBAs: (function() {
          if (!formData.deleteDBAs || !formData.deleteDBAs.length) {
            return {};
          }
          return { DBA: formData.deleteDBAs };
        })(),
        Website: formData.website,
        MainPhoneNumber: formData.mainPhoneNumber,
        EntityTypeID: formData.entityTypeId,
        CharterTypeID: formData.charterTypeId,
        CharteredOnDate: formData.charteredOnDate,
        OwnershipStructureID: formData.ownershipStructureId,
        FOMTypeID: formData.FOMTypeId,
        CorporateStructureID: formData.corporateStructureTypeId,
        StatusID: formData.statusId,
        StatusDate: formData.statusDate,
        RSSDIDNumber: formData.rssdidNumber,
        CertificationNumber: formData.certificationNumber,
        IDCharterNumber: formData.idCharterNumber,
        CertificateOfAuthorityToDoBusinessInPennsylvania: formData.certificateOfAuthorityToDoBusinessInPennsylvania
          ? '1'
          : '0',
        CertificateOfAuthorizationToEngageInFiduciaryActivity: formData.certificateOfAuthorizationToEngageInFiduciaryActivity
          ? '1'
          : '0',
        CertifiedStatementOfMinimumCapital: formData.certifiedStatementOfMinimumCapital ? '1' : '0',
        LetterOfReciprocity: formData.letterOfReciprocity ? '1' : '0',
        ProofOfMinimumCapital: +formData.proofOfMinimumCapital ? '1' : '0',
        FRSRSSD: formData.frsrssd,
        OCCCharter: formData.occCharter,
        FDICUniNum: formData.fdicUniNum,
        IsComplex: formData.isComplex,
        IsLowIncome: formData.isLowIncome,
        IsMemberBusinessLoans: formData.isMBL,
        IsFHLBMember: formData.isFHLBMember,
        IsCollectiveInvestmentFund: formData.isCollectiveInvestmentFund,
        SupervisoryCommittee: formData.supervisoryCommittee,
        TotalAssetsAsOfDate: formData.totalAssetsAsOfDate,
        TotalAssetsAmount: formData.totalAssetsAmount,
        TotalAssetsUnderManagementAsOfDate: formData.totalAssetsUnderManagementAsOfDate,
        TotalAssetsUnderManagementAmount: formData.totalAssetsUnderManagementAmount,
        TotalCapitalAsOfDate: formData.totalCapitalAsOfDate,
        TotalCapitalAmount: formData.totalCapitalAmount,
        FederalRegulatorID: formData.federalRegulatorId,
        RegulatorDivisionID: formData.regulatorDivisionId,
        FieldSupervisor: formData.fieldSupervisor,
        FieldSupervisorId: formData.fieldSupervisorId,
        CaseManager: formData.caseManagerUserId,
        StateRegulator: formData.stateRegulator,
        HoldingCompanyEntityID: formData.holdingCompanyId,
        SubsidaryBankEntityID: formData.subsidaryBankId,
        NextExamConductedByID: formData.nextExamConductedById,
        NextExamYear: formData.nextExamYear,
        NextExamTypeID: formData.nextExamTypeId,
        NextExamStartDate: formData.nextExamStartDate,
        EntityEmail: formData.entityEmail,
        PPOBPhone: formData.ppobPhone,
        PPOBFax: formData.ppobFax,
        IsSalesFinanceCoPa: formData.isSalesFinanceCoPa,
        PPOBAddress: (function() {
          if (!ppob) {
            return {};
          }
          return {
            AddressLine1: ppob.addressLine1,
            AddressLine2: ppob.addressLine2,
            AddressLine3: ppob.addressLine3,
            City: ppob.city,
            State: ppob.state,
            PostalCode: ppob.postalCode,
            Country: ppob.country,
            County: ppob.county
          };
        })(),
        IsMailingSame: formData.isMailingSame,
        MailingAddress: (function() {
          if (!mailing) {
            return {};
          }
          return {
            AddressLine1: mailing.addressLine1,
            AddressLine2: mailing.addressLine2,
            AddressLine3: mailing.addressLine3,
            City: mailing.city,
            State: mailing.state,
            PostalCode: mailing.postalCode,
            Country: mailing.country,
            County: mailing.county
          };
        })(),
        IsBillingSame: formData.isBillingSame,
        BillingAddress: (function() {
          if (!billing) {
            return {};
          }
          return {
            AddressLine1: billing.addressLine1,
            AddressLine2: billing.addressLine2,
            AddressLine3: billing.addressLine3,
            City: billing.city,
            State: billing.state,
            PostalCode: billing.postalCode,
            Country: billing.country,
            County: billing.county
          };
        })(),
        FFCharteringState: formData.FFCharteringState,
        FFPrimaryRegulator: formData.FFPrimaryRegulator,
        FFCapitalAmount: formData.FFCapitalAmount,
        FFCapitalAsOfDate: formData.FFCapitalAsOfDate,
        FFCapitalComments: formData.FFCapitalComments,
        FFDeptActionID: formData.FFDeptActionId,
        FFDeptActionDate: formData.FFDeptActionDate,
        FFTerminationDate: formData.FFTerminationDate,
        FFTerminationReason: formData.FFTerminationReason
      }
    }
  });
};
