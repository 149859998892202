var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "              <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {});

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + "</h1>\r\n  <div class=\"labels-container\">\r\n    <div class=\"flagged-label-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar-container\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n\r\n<div class=\"container-fluid m-b-lg\">\r\n  <form class=\"form-horizontal review-form\">\r\n    <div class=\"col-sm-10 col-sm-offset-1\">\r\n      <div class=\"case-summary-container\">\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Jump To</label>\r\n          <div class=\"form-control-static col-sm-8 col-md-9 jump-to-container\"></div>\r\n        </div>\r\n        <div class=\"summary-container\"></div>\r\n        <div class=\"form-group\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Case Background</label>\r\n          <p class=\"form-control-static col-sm-8 col-md-9\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.backgroundInfo : stack1), depth0))
    + "</p>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Decision</label>\r\n        <div class=\"col-sm-6 col-md-7 \">\r\n          <select name=\"decisionId\" class=\"decision-select form-control\" data-parsley-required=\"true\"\r\n            data-parsley-errors-container=\".decision-select-error-container\">\r\n            <option value=\"\" selected>--</option>\r\n            <option value=\"2101\">Accept Request as Compliance Case</option>\r\n            <option value=\"2102\">Close Request</option>\r\n          </select>\r\n          <div class=\"decision-select-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"accept-inputs\">\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Admin</label>\r\n          <div class=\"col-sm-6 col-md-7\">\r\n            <select name=\"admin\" class=\"case-admin-select form-control\" data-parsley-required=\"true\"\r\n              data-parsley-errors-container=\".case-admin-select-error-container\">\r\n              <option value=\"\">--</option>\r\n"
    + ((stack1 = helpers.each.call(alias3,(depth0 != null ? depth0.complianceAdmins : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </select>\r\n            <div class=\"case-decision-select-error-container\"></div>\r\n          </div>\r\n        </div>\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Date Received</label>\r\n          <div class=\"col-sm-6 col-md-7\">\r\n            <div class=\"input-group\">\r\n              <input type=\"text\" name=\"dateReceived\" class=\"form-control datepicker date-received\"\r\n                placeholder=\"Date Received\" value=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.submissionDate : stack1), depth0))
    + "\" data-parsley-required=\"true\"\r\n                data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\r\n                data-parsley-errors-container=\"#dateReceivedErrorsContainer\" />\r\n              <div class=\"input-group-addon\">\r\n                <i class=\"fa fa-calendar\"></i>\r\n              </div>\r\n            </div>\r\n            <div id=\"dateReceivedErrorsContainer\"></div>\r\n          </div>\r\n        </div>\r\n        <div class=\"form-group required\">\r\n          <label class=\"control-label col-sm-4 col-md-3\">Date Assigned</label>\r\n          <div class=\"col-sm-6 col-md-7\">\r\n            <div class=\"input-group\">\r\n              <input type=\"text\" name=\"dateAssigned\" class=\"form-control datepicker date-assigned\"\r\n                placeholder=\"Date Assigned\" value=\""
    + alias2(((helper = (helper = helpers.currentDate || (depth0 != null ? depth0.currentDate : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias3,{"name":"currentDate","hash":{},"data":data}) : helper)))
    + "\" data-parsley-required=\"true\" data-parsley-date=\"\"\r\n                data-parsley-trigger-after-failure=\"changeDate\"\r\n                data-parsley-errors-container=\"#dateAssignedErrorsContainer\" />\r\n              <div class=\"input-group-addon\">\r\n                <i class=\"fa fa-calendar\"></i>\r\n              </div>\r\n            </div>\r\n            <div id=\"dateAssignedErrorsContainer\"></div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required accept-narrative\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Narrative</label>\r\n        <div class=\"col-sm-6 col-md-7\">\r\n          <textarea name=\"decisionReason\" class=\"form-control\" placeholder=\"Compliance Case Narrative\" rows=\"10\"\r\n            data-parsley-required=\"true\" data-parsley-errors-container=\".narrative-error-container\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complianceDetails : depth0)) != null ? stack1.backgroundInfo : stack1), depth0))
    + "</textarea>\r\n          <div class=\"narrative-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"form-group required reject-reason\">\r\n        <label class=\"control-label col-sm-4 col-md-3\">Close Reason</label>\r\n        <div class=\"col-sm-6 col-md-7\">\r\n          <textarea name=\"decisionReason\" class=\"form-control\" placeholder=\"Compliance Case Close Reason\" rows=\"10\"\r\n            data-parsley-required=\"true\" data-parsley-errors-container=\".close-reason-error-container\"></textarea>\r\n          <div class=\"close-reason-error-container\"></div>\r\n        </div>\r\n      </div>\r\n      <div class=\"col-sm-8 col-md-9 col-sm-offset-4 col-md-offset-3\">\r\n        <a class=\"btn btn-default btn-cancel\" href=\"#nondep/dashboard\">Cancel</a>\r\n        <button class=\"btn btn-primary btn-submit\">Submit</button>\r\n        <div class=\"task-actions\"></div>\r\n      </div>\r\n  </form>\r\n</div>";
},"useData":true});