var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return alias2(alias1(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.complaintTypeDisplay : stack1), depth0))
    + "Complaint -\r\n    "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1), depth0));
},"3":function(container,depth0,helpers,partials,data) {
    return "New\r\n    Complaint";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.complaint : depth0)) != null ? stack1.caseNumber : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h1>\r\n  <div class=\"labels-container\">\r\n    <div class=\"litigation-hold-label-container\"></div>\r\n  </div>\r\n  <div class=\"page-title-buttons-container\">\r\n    <div class=\"cs-link-container\"></div>\r\n    <div class=\"complaint-action-drop-down\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"row m-b-md\">\r\n  <div class=\"col-lg-10 col-sm-12\">\r\n    <div class=\"complaint-dashboard-container\"></div>\r\n  </div>\r\n</div>";
},"useData":true});