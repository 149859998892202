var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.taskType : stack1)) != null ? stack1.description : stack1), depth0))
    + " for "
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n  <div class=\"labels-container\">\r\n    <div class=\"flagged-label-container\"></div>\r\n  </div>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"task-status\"></div>\r\n<div class=\"form-horizontal\">\r\n  <form class=\"correspondence-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Entity Name</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.entityName : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Contact Date</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static \">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestedOn : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h3>Correspondence</h3>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3\">Message From Entity</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <p class=\"form-control-static\">"
    + alias2((helpers["default"] || (depth0 && depth0["default"]) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.correspondence : depth0)) != null ? stack1.requestComments : stack1),"--",{"name":"default","hash":{},"data":data}))
    + "</p>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label col-sm-3\">Reply Comments</label>\r\n      <div class=\"col-sm-9 col-md-6\">\r\n        <textarea type=\"text\" class=\"form-control \" name=\"replyComments\" placeholder=\"Comments\" rows=\"5\"\r\n          data-parsley-required=\"true\"></textarea>\r\n      </div>\r\n    </div>\r\n    <div class=\"row\">\r\n      <div class=\"col-sm-9 col-sm-offset-3\">\r\n        <h3>Documents</h3>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 \">Attach file for Correspondence</label>\r\n      <div class=\" col-sm-9 col-md-6\">\r\n        <div class=\"ext-file-upload-table-container\"></div>\r\n      </div>\r\n    </div>\r\n    <div class=\"form-group\">\r\n      <label class=\"control-label col-sm-3 \">Document List</label>\r\n      <div class=\" col-sm-9 col-md-6\">\r\n        <div class=\"int-file-upload-table-container\"></div>\r\n      </div>\r\n    </div>\r\n  </form>\r\n  <div class=\"form-group\">\r\n    <div class=\"col-sm-9 col-lg-7 col-sm-offset-3 m-b-lg\">\r\n      <a href=\"#nondep/dashboard\" class=\"btn btn-default\" role=\"button\">Cancel</a>\r\n      <button type=\"button\" class=\"btn btn-primary submit\">Submit</button>\r\n      <div class=\"task-actions\"></div>\r\n    </div>\r\n  </div>\r\n</div>";
},"useData":true});