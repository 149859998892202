/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var moment = require('moment');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var defer = $.Deferred();
  var supportingDocuments = data.supportingDocuments || [];

  $.soap({
    body: {
      SubmitEntityAudit: {
        $xmlns: 'http://dobs.pa.gov/dp/audit/bpm/1.0',
        EntityID: data.entityId,
        AuditID: data.auditId,
        AuditDate: data.auditDate ? moment(data.auditDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        ReceivedDate: data.receivedDate ? moment(data.receivedDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        AcknowledgedDate: data.acknowledgedDate
          ? moment(data.acknowledgedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
          : null,
        AuditTypeID: data.auditTypeId,
        AuditTrustTypeID: data.auditTrustTypeId,
        OpinionID: data.opinionId,
        OpinionDetails: data.opinionDetails,
        GoingConcern: data.goingConcern,
        AuditFirmID: data.auditFirmId,
        InternalAuditFirmID: data.internalAuditFirmId,
        ApprovedDate: data.approvedDate ? moment(data.approvedDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        LastRevisedDate: data.lastRevisedDate ? moment(data.lastRevisedDate, 'MM/DD/YYYY').format('YYYY-MM-DD') : null,
        LetterReceivedDate: data.letterReceivedDate
          ? moment(data.letterReceivedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
          : null,
        ResponseReceivedDate: data.responseReceivedDate
          ? moment(data.responseReceivedDate, 'MM/DD/YYYY').format('YYYY-MM-DD')
          : null,
        IsDeleted: data.isDeleted,
        Comments: data.comments,
        SupportingDocuments: (function() {
          if (!supportingDocuments.length) {
            return {};
          }
          return {
            Document: _.map(supportingDocuments, function(supportingDocument) {
              return {
                DocumentID: supportingDocument.fileId
              };
            })
          };
        })()
      }
    },
    blueprint: {
      newAuditId: '$..NewAuditID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, { cleanUpTextKeys: true });
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
