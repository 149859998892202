/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');
var _ = require('underscore');

var BreadcrumbView = require('common/views/BreadcrumbView');
var modelGet = require('common/util/modelGet');
var HeaderBarView = require('common/views/HeaderBarView');
var toggleForm = require('common/util/toggleForm');

var SubmitOrganizationLicense = require('../services/SubmitOrganizationLicense');

var tmplSecOrganizationLicenseMainEditView = require('../templates/SecOrganizationLicenseMainEditView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecOrganizationLicenseMainEditView,

  className: 'sec-organization-license-main-edit-view container-fluid',

  ui: {
    breadcrumb: '.breadcrumb-container',
    headerBar: '.header-bar-container',
    submit: '.btn-submit',
    statusDate: '.status-date',
    FINRADate: '.finra-date',
    SECRegistrationDate: '.sec-registration-date',
    organizationLicenseMainForm: '.organization-license-main-form',
    licenseCustodySelect: '.license-custody-select',
    licenseCustodySelectContainer: '.license-custody-select-container',
    fiscalYearEnd: '.fiscal-year-end',
    licenseTypeDropdown: 'select[name="licenseTypeId"]',
    licenseStatusDropdown: 'select[name="licenseStatusId"]'
  },

  regions: {
    breadcrumb: '@ui.breadcrumb',
    headerBar: '@ui.headerBar',
    licenseCustodySelect: '@ui.licenseCustodySelect'
  },

  events: {
    'click @ui.submit': 'onClickSubmit',
    'change @ui.licenseTypeDropdown': 'onChangeLicenseType',
    'change @ui.licenseStatusDropdown': 'onChangeLicenseStatus'
  },

  templateHelpers: function() {
    var filingExaminerUsers = modelGet(this.model, 'filingExaminerUsers', []);

    var filingExaminerUserId = modelGet(this.model, 'license.filingExaminerUserId');
    var filingExaminerName = modelGet(this.model, 'license.filingExaminerUserName');

    /*
     * Current examiner user could no longer have examiner role, add current
     * examiner with internal user data if username does not exist in
     * examiners array
     */
    var currentFilingExaminerHasRole = _.find(filingExaminerUsers, function(cm) {
      return filingExaminerUserId === cm.userId;
    });

    if (filingExaminerUserId && !currentFilingExaminerHasRole) {
      var currentFilingExaminer = {
        userId: filingExaminerUserId,
        name: filingExaminerName
      };
      filingExaminerUsers.splice(
        _.sortedIndex(filingExaminerUsers, currentFilingExaminer, 'name'),
        0,
        currentFilingExaminer
      );
    }

    return {
      filingExaminerUsers: filingExaminerUsers
    };
  },

  onBeforeShow: function() {
    var license = this.model.get('license') || {};
    var licenseId = license.id;

    var licenseIdentifiers = this.model.get('licenseIdentifiers') || {};
    var organizationId = licenseIdentifiers.organizationId;

    licenseIdentifiers.title = 'License';
    var headerBarColumns = [
      {
        name: 'CRDNumber',
        label: 'CRD Number',
        minWidth: '160px',
        maxWidth: '200px'
      }
    ];

    this.showChildView(
      'headerBar',
      new HeaderBarView({
        columns: new Backbone.Collection(headerBarColumns),
        model: new Backbone.Model(licenseIdentifiers)
      })
    );

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#sec/dashboard',
            text: 'Home'
          },
          {
            link: '#sec/organization/' + organizationId + '/dashboard',
            text: licenseIdentifiers.applicantLegalName
          },
          {
            link: '#sec/organization/' + organizationId + '/license/' + licenseId + '/dashboard',
            text: licenseIdentifiers.type
          },
          {
            text: 'Edit Organization License'
          }
        ]
      })
    );

    toggleForm(this.ui.licenseCustodySelectContainer, license.licenseTypeId === '2');

    Syphon.deserialize(this, this.model.get('license'));

    this.ui.statusDate.datepicker();
    this.ui.FINRADate.datepicker();
    this.ui.SECRegistrationDate.datepicker();
    this.ui.fiscalYearEnd.datepicker({
      format: 'mm/dd',
      maxViewMode: 'months'
    });

    this.ui.licenseCustodySelect.select2();
    var custodyTypeIds = this.model.get('license').custodyTypeIds;
    this.ui.licenseCustodySelect.val(custodyTypeIds).trigger('change');
  },

  onChangeLicenseType: function() {
    var formData = Syphon.serialize(this);
    toggleForm(this.ui.licenseCustodySelectContainer, formData.licenseTypeId === '2');
  },

  onChangeLicenseStatus: function() {
    this.ui.statusDate.datepicker('setDate', new Date());
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = this.getFormData();
        SubmitOrganizationLicense(formData)
          .done(function() {
            Radio.channel('navigate').trigger(
              'show:int:sc:organization:license',
              formData.organizationId,
              formData.licenseId
            );
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.organizationLicenseMainForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);
    formData.licenseId = modelGet(this.model, 'license.id');
    formData.organizationId = modelGet(this.model, 'licenseIdentifiers.organizationId');
    return formData;
  }
});
