/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var _get = require('lodash.get');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(formData) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      SubmitNewExam: {
        $xmlns: 'http://dobs.pa.gov/dp/exam/bpm/1.0',
        EntityID: formData.entityId,
        TypeID: formData.examTypeId,
        ScopeID: formData.examScopeId,
        OtherScope: formData.otherScope,
        ScopeSubcategory: formData.scopeSubcategory,
        TargetVisitationCode: formData.targetVisitationCode,
        FolderCreationAsOfDate: formData.folderCreationAsOfDate,
        ConductedBys: (function() {
          if (!_get(formData, 'conductedBy.length')) {
            return {};
          }
          return _.map(formData.conductedBy, function(id) {
            return { ConductedByID: id };
          });
        })(),
        LeadAgencyID: formData.leadAgencyId,
        OtherLeadAgency: formData.otherLeadAgency,
        ExamAreas: (function(formData) {
          var examAreas = [];
          if (formData.ssArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.ssArea.examAreaId,
              AsOfDate: formData.ssArea.asOfDate,
              AssetSize: formData.ssArea.assetSize,
              IsNotifyEntity: +formData.ssArea.isNotifyEntity,
              FirstDayLetterDate: formData.ssArea.firstDayLetterDate,
              FirstDayLetterDocID: formData.ssArea.firstDayLetterDocId,
              EIC: formData.ssArea.eic,
              FederalEIC: formData.ssArea.federalEIC,
              ExamStartDate: formData.ssArea.examStartDate,
              ExitMeetingDate: formData.ssArea.exitMeetingDate,
              ExamHours: formData.ssArea.examHours
            });
          }
          if (formData.trustArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.trustArea.examAreaId,
              AsOfDate: formData.trustArea.asOfDate,
              IsNotifyEntity: +formData.trustArea.isNotifyEntity,
              FirstDayLetterDate: formData.trustArea.firstDayLetterDate,
              FirstDayLetterDocID: formData.trustArea.firstDayLetterDocId,
              EIC: formData.trustArea.eic,
              FederalEIC: formData.trustArea.federalEIC,
              ExamStartDate: formData.trustArea.examStartDate,
              ExitMeetingDate: formData.trustArea.exitMeetingDate,
              ExamHours: formData.trustArea.examHours
            });
          }
          if (formData.itArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.itArea.examAreaId,
              AsOfDate: formData.itArea.asOfDate,
              IsNotifyEntity: +formData.itArea.isNotifyEntity,
              FirstDayLetterDate: formData.itArea.firstDayLetterDate,
              FirstDayLetterDocID: formData.itArea.firstDayLetterDocId,
              EIC: formData.itArea.eic,
              FederalEIC: formData.itArea.federalEIC,
              ExamStartDate: formData.itArea.examStartDate,
              ExitMeetingDate: formData.itArea.exitMeetingDate,
              ExamHours: formData.itArea.examHours
            });
          }
          if (formData.complianceArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.complianceArea.examAreaId,
              AsOfDate: formData.complianceArea.asOfDate
            });
          }
          if (formData.craArea) {
            examAreas.push({
              EntityID: formData.entityId,
              ExamAreaID: formData.craArea.examAreaId,
              AsOfDate: formData.craArea.asOfDate
            });
          }

          if (examAreas.length > 0) {
            return {
              ExamArea: examAreas
            };
          }
          return {};
        })(formData),
        IsHoldingCompany: +formData.isHoldingCompany,
        IsConcurrent: +formData.isConcurrent,
        IsParticipation: +formData.isParticipation,
        FirstDayLetters: (function() {
          if (!_get(formData, 'firstDayLetters.length')) {
            return {};
          }
          return _.map(formData.firstDayLetters, function(firstDayLetter) {
            return { DocumentID: firstDayLetter.fileId };
          });
        })(),
        Comments: formData.comments,
        DataCenter: formData.dataCenter,
        EIC: formData.eicId,
        ReviewRoles: (function() {
          if (!_get(formData, 'reviewRoles.length')) {
            return {};
          }
          return _.map(formData.reviewRoles, function(id) {
            return { RoleID: id };
          });
        })(),
        OwnerExternalUserID: formData.ownerExternalUserId
      }
    },
    blueprint: {
      examId: '$..ExamID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      deferred.resolve(response.examId);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
