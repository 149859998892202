/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2020] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
//var Syphon = require('backbone.syphon');
//var _ = require('underscore');

var tmplConSerComplaintContactCollectionView = require('../templates/ConSerComplaintContactCollectionView.hbs');
var ConSerComplaintContactInformationDisplayView = require('./ConSerComplaintContactInformationDisplayView');

module.exports = Marionette.CollectionView.extend({
  template: tmplConSerComplaintContactCollectionView,

  className: 'con-ser-contact-collection-view',

  childView: ConSerComplaintContactInformationDisplayView,

  childEvents: {
    'add:contact': 'onChildClickAddContact',
    'delete:contact': 'onChildClickDeleteContact'
  },

  onChildClickAddContact: function(childView) {
    this.collection.push({ contacts: childView.model.get('contacts') });
  },

  onChildClickDeleteContact: function(childView) {
    if (this.collection.length > 1) {
      this.collection.remove(childView.model);
    } else {
      this.collection.remove(childView.model);
      this.collection.push({ contacts: childView.model.get('contacts') });
    }
  },

  getFormData: function() {
    var formData = this.children.map(function(child) {
      return child.getFormData();
    });
    return formData;
  }
});
