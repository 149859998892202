var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + " - ("
    + alias4(((helper = (helper = helpers.userId || (depth0 != null ? depth0.userId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"userId","hash":{},"data":data}) : helper)))
    + ")</option>\r\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <option value=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.staffFunction || (depth0 != null ? depth0.staffFunction : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"staffFunction","hash":{},"data":data}) : helper)))
    + "</option>\r\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\r\n<div class=\"panel-heading\" role=\"tab\" id=\"heading"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\r\n  <div class=\"panel-title\">\r\n    <a data-toggle=\"collapse\" data-parent=\"#accordion\" href=\"#collapse"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"true\"\r\n      aria-controls=\"collapse"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\r\n      New Staff Member\r\n    </a>\r\n    <button class=\"btn btn-sm btn-danger remove-staff-assigned pull-right\">Remove</button>\r\n  </div>\r\n</div>\r\n<div id=\"collapse"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-collapse collapse in\" role=\"tabpanel\"\r\n  aria-labelledby=\"heading"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\">\r\n  <div class=\"panel-body\">\r\n    <form class=\"form-horizontal\">\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12 col-md-6\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-3 col-md-4\">Staff Member</label>\r\n            <div class=\"col-sm-9 col-md-8 \">\r\n              <select name=\"staffMember\" class=\"form-control\" data-parsley-required=\"true\">\r\n                <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.staffMembers : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </select>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-12 col-md-6\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-3 col-md-4\">Function</label>\r\n            <div class=\"col-sm-9 col-md-8 \">\r\n              <select name=\"functionId\" class=\"form-control\" data-parsley-required=\"true\">\r\n                <option value=\"\" selected>--</option>\r\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.examInvInqStaffFunctions : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </select>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n      <div class=\"row\">\r\n        <div class=\"col-sm-12 col-md-6\">\r\n          <div class=\"form-group required\">\r\n            <label class=\"control-label col-sm-3 col-md-4\">Assigned Date</label>\r\n            <div class=\"col-sm-9 col-md-8\">\r\n              <div class=\"input-group\">\r\n                <input type=\"text\" name=\"assignedDate\" class=\"form-control date-picker\" placeholder=\"Assigned Date\"\r\n                  data-parsley-required=\"true\" data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\r\n                  data-parsley-errors-container=\"#assignedDateErrorsContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n                <div class=\"input-group-addon\">\r\n                  <i class=\"fa fa-calendar\"></i>\r\n                </div>\r\n              </div>\r\n              <div id=\"assignedDateErrorsContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n        <div class=\"col-sm-12 col-md-6\">\r\n          <div class=\"form-group \">\r\n            <label class=\"control-label col-sm-3 col-md-4\">Removed Date</label>\r\n            <div class=\"col-sm-9 col-md-8\">\r\n              <div class=\"input-group\">\r\n                <input type=\"text\" name=\"removedDate\" class=\"form-control date-picker\" placeholder=\"Removed Date\"\r\n                  data-parsley-date=\"\" data-parsley-trigger-after-failure=\"changeDate\"\r\n                  data-parsley-errors-container=\"#removedDateErrorsContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\" />\r\n                <div class=\"input-group-addon\">\r\n                  <i class=\"fa fa-calendar\"></i>\r\n                </div>\r\n              </div>\r\n              <div id=\"removedDateErrorsContainer"
    + alias4(((helper = (helper = helpers.childIndex || (depth0 != null ? depth0.childIndex : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"childIndex","hash":{},"data":data}) : helper)))
    + "\"></div>\r\n            </div>\r\n          </div>\r\n        </div>\r\n      </div>\r\n    </form>\r\n  </div>\r\n</div>";
},"useData":true});