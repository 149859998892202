/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

module.exports = function(complianceId) {
  var defer = $.Deferred();

  $.soap({
    body: {
      GetComplianceContactByCompliance: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        ComplianceID: complianceId
      }
    },
    blueprint: {
      id: '$..ID.__text',
      firstName: '$..FirstName.__text',
      lastName: '$..LastName.__text',
      phoneNumber: '$..PhoneNumber.__text',
      email: '$..Email.__text',
      addressId: '$..AddressID.__text',
      address: {
        addressLine1: '$..AddressLine1.__text',
        addressLine2: '$..AddressLine2.__text',
        addressLine3: '$..AddressLine3.__text',
        county: '$..County.__text',
        city: '$..City.__text',
        state: '$..State.__text',
        postalCode: '$..PostalCode.__text',
        country: '$..Country.__text'
      }
    }
  })
    .done(function(response) {
      defer.resolve(response);
    })
    .fail(function(err) {
      defer.reject(err);
    });

  return defer.promise();
};
