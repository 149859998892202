/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(data) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetContactsByEntityAndDepartment: {
        $xmlns: 'http://dobs.pa.gov/cs/contact/bpm/1.0',
        DepartmentID: data.departmentId,
        EntityID: data.entityId,
        LicenseTypeID: data.licenseTypeId
      }
    },
    blueprintArrays: ['GetContactsByEntityAndDepartmentResponse.CS_Contact'],
    blueprint: {
      contacts: [
        '$..CS_Contact',
        {
          contactId: '$..ID',
          departmentId: '$..DepartmentID',
          entityId: '$..EntityID',
          firstName: '$..FirstName',
          lastName: '$..LastName',
          title: '$..Title',
          phoneNumber: '$..PhoneNumber',
          fax: '$..Fax',
          email: '$..Email',
          addressLine1: '$..AddressLine1',
          addressLine2: '$..AddressLine2',
          addressLine3: '$..AddressLine3',
          county: '$..County',
          city: '$..City',
          state: '$..State',
          postalCode: '$..PostalCode',
          country: '$..Country',
          licenseTypeId: '$..LicenseTypeID',
          isDeleted: '$..IsDeleted',
          contactType: '$..CSCTType',
          contactTypeID: '$..ContactTypeID',
          contactTypeDesc: '$..ContactType'
        }
      ]
    }
  })
    .done(function(response) {
      cleanUpResponse(response);
      // Add a UUID to each contact so these contacts can be merged with
      // contacts from other departments in Consumer Services.
      _.each(response.contacts, function(contact) {
        contact.firstName = contact.firstName || '--';
        contact.lastName = contact.lastName || '--';
        contact.contactType = contact.contactType || '--';
        contact.email = contact.email || '--';
        contact.phoneNumber = contact.phoneNumber || '--';
      });
      response.contacts = _.filter(response.contacts, function(contact) {
        return contact.isDeleted !== '1';
      });
      deferred.resolve(response.contacts);
    })
    .fail(function(err) {
      deferred.reject();
    });

  return deferred.promise();
};
