/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var Syphon = require('backbone.syphon');

var BreadcrumbView = require('common/views/BreadcrumbView');

var SubmitComplaintLitigationHoldStatus = require('../services/SubmitComplaintLitigationHoldStatus');

require('../styles/ConSerComplaintEditLitigationHoldView.css');

var tmplConSerComplaintEditLitigationHoldView = require('../templates/ConSerComplaintEditLitigationHoldView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplConSerComplaintEditLitigationHoldView,

  className: 'con-ser-complaint-edit-litigation-hold-view container-fluid m-b-lg',

  ui: {
    breadcrumb: '.breadcrumb-container',
    litigationHoldForm: '.litigation-hold-form',
    litigationHoldNotesContainer: '.litigation-hold-notes-container',
    submit: '.btn-submit'
  },

  regions: {
    litigationHoldNotesContainer: '@ui.litigationHoldNotesContainer',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  onBeforeShow: function() {
    var complaint = this.model.get('complaint');

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: '#conser/dashboard',
            text: 'Home'
          },
          {
            link: '#conser/complaint/' + complaint.complaintId + '/dashboard',
            text: 'Complaint - ' + complaint.caseNumber
          },
          {
            text: 'Edit Litigation Hold Status'
          }
        ]
      })
    );
  },

  onClickSubmit: function(e) {
    e.preventDefault();
    var complaint = this.model.get('complaint');

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        var formData = Syphon.serialize(this);
        var litigationHoldNotes = formData.litigationHoldNotes;
        var newLitigationHoldStatus = formData.placeLitigationHold ? '1' : '0';
        SubmitComplaintLitigationHoldStatus({
          complaintId: complaint.complaintId,
          newLitigationHoldStatus: newLitigationHoldStatus,
          litigationHoldNotes: litigationHoldNotes
        })
          .done(function() {
            Radio.channel('navigate').trigger('show:int:cs:complaint', complaint.complaintId);
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  },

  validate: function() {
    return this.ui.litigationHoldForm.parsley().whenValidate();
  }
});
