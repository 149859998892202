/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var NProgress = require('nprogress');

var GetDocumentTypesByDepartment = require('common/services/GetDocumentTypesByDepartment');

var GetCorrespondence = require('../../services/GetCorrespondence');
var ReviewCorrespondenceTaskView = require('./ReviewCorrespondenceTaskView');
var GetEntityFlaggedStatus = require('../../../entity-dashboard/services/GetEntityFlaggedStatus');

module.exports = {
  event: 'build:nd:review-correspondence',

  channel: 'task',

  builder: function(task) {
    var defer = $.Deferred();
    var correspondenceId = task.taskData.ND.correspondenceId;
    $.when(GetCorrespondence(correspondenceId), GetDocumentTypesByDepartment('NON_DEPOSITORY'))
      .done(function(correspondence, documentTypes) {
        GetEntityFlaggedStatus(correspondence.entityId)
          .done(function(entityIsFlagged) {
            NProgress.set(0.85);
            defer.resolve(
              new ReviewCorrespondenceTaskView({
                model: new Backbone.Model({
                  task: task,
                  taskId: task.taskId,
                  correspondenceId: correspondenceId,
                  correspondence: correspondence,
                  documentTypes: documentTypes,
                  entityIsFlagged: entityIsFlagged
                })
              })
            );
          })
          .fail(function(err) {
            defer.reject(err);
          });
      })
      .fail(function(err) {
        defer.reject(err);
      });
    return defer.promise();
  }
};
