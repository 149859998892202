var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "\"#ext/conser/complaint/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)))
    + "/dashboard\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "\"#ext/conser/dashboard\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n\r\n<div class=\"form-horizontal\">\r\n  <form class=\"authority-form\">\r\n    <div class=\"form-group\">\r\n      <label class=\"input-label col-sm-12 required-field-disclaimer-text\">\r\n        <i class=\"fa fa-info-circle\"></i>\r\n      </label>\r\n    </div>\r\n    <div class=\"form-group required\">\r\n      <label class=\"control-label font-weight-normal authority-label\" id=\"AuthorityViewCheckBoxLabel\">\r\n        <input type=\"checkbox\" name=\"isAffirmed\" value=\"1\" class=\"authority-checkbox\" id=\"AuthorityViewCheckBox\"\r\n          data-parsley-required=\"true\" />\r\n        All information provided may be shared in order to address your inquiry.\r\n      </label>\r\n    </div>\r\n\r\n    <div class=\"alert alert-danger collapse validation-errors\" role=\"alert\">\r\n      <i class=\"fa fa-exclamation-triangle\" aria-hidden=\"true\"></i>\r\n      Validation errors were found while attempting to submit the form. Please fix these issues and resubmit.\r\n      <br /><br />Sections\r\n      that contain validation errors:<br />\r\n      <span class=\"validation-error-sections\" />\r\n    </div>\r\n\r\n    <p>\r\n      <a href="
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isExtEdit : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\r\n        class=\"btn btn-default btn-md\" role=\"button\">Cancel</a>\r\n      <button class=\"btn btn-primary btn-md submit-new-complaint ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n        <span class=\"ladda-label\">Submit</span>\r\n      </button>\r\n    </p>\r\n  </form>\r\n</div>";
},"useData":true});