/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');
var moment = require('moment');

var PageableCollection = require('psa-backbone/models/PageableCollection');
var GridView = require('psa-marionette/views/GridView');

var LinkCell = require('common/backgrid/LinkCell');
var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');

var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');
var SearchSecExams = require('../services/SearchSecExams');

require('../styles/SecExamSearchView.css');
var tmplSecExamSearchView = require('../templates/SecExamSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecExamSearchView,

  className: 'sec-exam-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchForm: 'form',
    datepickers: '.datepicker',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'dbaName',
          label: 'Organization DBA Name',
          cell: LinkCell.extend({
            emptyText: '--',
            href: function(model) {
              var orgId = model.get('organizationId');
              var url = orgId ? '#sec/organization/' + orgId + '/dashboard' : '';
              return url;
            }
          }),
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'legalName',
          label: 'Applicant Legal Name',
          cell: 'string',
          headerCell: 'custom',
          width: 25,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: 'licenseType',
          label: 'License Type',
          cell: 'string',
          headerCell: 'custom',
          width: 20,
          editable: false
        },
        {
          name: 'examNumber',
          label: 'Exam Number',
          cell: 'string',
          headerCell: 'custom',
          width: 10,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var examId = model.get('examId');
              return '#sec/exam/' + examId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 10
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Exams Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.ui.datepickers.datepicker();

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    this.ui.searchForm
      .parsley()
      .whenValidate()
      .done(function() {
        var data = Syphon.serialize(self, {
          inputReaders: Syphon.SearchInputReaderSet
        });
        // If no inputs were given, do not perform a search.
        if (
          !(
            data.dbaName ||
            data.crdNumber ||
            data.licenseTypeId ||
            data.openedDate ||
            data.examNumber ||
            data.examStatusId ||
            data.examTypeId ||
            data.examCategoryId ||
            data.primaryExaminer ||
            data.staffAssigned ||
            data.conductedLocationId ||
            data.officeAssignedId ||
            data.legalName
          )
        ) {
          return;
        }
        self.searchSummaryView.reset();

        var l = Ladda.create(self.ui.search[0]);
        l.start();
        var body = bodybuilder();

        if (data.dbaName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'dbaName', {
              query: data.dbaName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'dbaName', data.dbaName.toLowerCase() + '*');
            b.orQuery('match', 'dbaName.phonetic', data.dbaName);
            return b;
          });
        }

        if (data.legalName) {
          body.query('bool', function(b) {
            b.orQuery('match', 'legalName', {
              query: data.legalName,
              fuzziness: 'AUTO',
              analyzer: 'standard'
            });
            b.orQuery('wildcard', 'legalName', data.legalName.toLowerCase() + '*');
            b.orQuery('match', 'legalName.phonetic', data.legalName);
            return b;
          });
        }

        if (data.crdNumber) {
          body.query('wildcard', 'crdNumber', data.crdNumber + '*');
        }

        if (data.licenseTypeId) {
          body.query('term', 'licenseTypeId', data.licenseTypeId);
        }

        if (data.openedDate) {
          var openedDateFormatted = moment(data.openedDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
          body.query('range', 'openedDate', {
            lte: openedDateFormatted,
            gte: openedDateFormatted
          });
        }

        if (data.examNumber) {
          body.query('wildcard', 'examNumber', data.examNumber + '*');
        }

        if (data.examStatusId) {
          body.query('term', 'examStatusId', data.examStatusId);
        }

        if (data.examTypeId) {
          body.query('term', 'examTypeId', data.examTypeId);
        }

        if (data.examCategoryId) {
          body.query('term', 'examCategoryId', data.examCategoryId);
        }

        if (data.primaryExaminer) {
          body.query('match', 'primaryExaminer', data.primaryExaminer);
        }

        if (data.conductedLocationId) {
          body.query('term', 'conductedLocationId', data.conductedLocationId);
        }

        if (data.officeAssignedId) {
          body.query('term', 'officeAssignedId', data.officeAssignedId);
        }

        if (data.staffAssigned) {
          body.query('nested', 'path', 'staffs', function(nb) {
            nb.andQuery('term', 'staffs.staffAssigned', data.staffAssigned);
            return nb;
          });
        }

        body.notQuery('term', 'isDeleted', '1');

        body.size(self.model.get('searchLimit'));
        var bodyObj = body.build();
        // Keep for testing
        // console.log(JSON.stringify(bodyObj, null, 2));
        SearchSecExams(bodyObj)
          .done(function(data) {
            l.stop();
            self.searchResults.collection.fullCollection.reset(data.hits);
            self.searchSummaryView.display(data);
          })
          .fail(function(err) {
            l.stop();
          });
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
