/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var _ = require('underscore');
var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Radio = require('backbone.radio');

var modelGet = require('common/util/modelGet');
var userHasRole = require('common/util/userHasRole');
var BreadcrumbView = require('common/views/BreadcrumbView');
var ContentServerLinkView = require('common/views/ContentServerLinkView');
var DropdownView = require('common/views/DropdownView');
var PanelGridView = require('common/views/PanelGridView');
var SideNavSectionView = require('common/views/SideNavSectionView');
var UIUtil = require('psa-core/util/ui');
var ConfirmationModalView = require('common/views/ConfirmationModalView');
var FlaggedLabelView = require('common/views/FlaggedLabelView');

var NMLSLabelView = require('../../common/views/NMLSLabelView');
var LicenseHeaderBarView = require('../../common/views/LicenseHeaderBarView');
var NonDepLicenseGeneralInformationView = require('./NonDepLicenseGeneralInformationView');
var NonDepLicenseBondsView = require('./NonDepLicenseBondsView');
var NonDepLicenseCertificateHistoryView = require('./NonDepLicenseCertificateHistoryView');
var NonDepLicenseAddressHistoryView = require('./NonDepLicenseAddressHistoryView');
var NonDepLicenseContactsView = require('../../common/views/NonDepLicenseContactsView');
var NonDepLicenseOfficeManagerView = require('./NonDepLicenseOfficeManagerView');
var NonDepLicenseMortgageOriginatorSponsorshipsView = require('./NonDepLicenseMortgageOriginatorSponsorshipsView');
var NonDepLicenseMortgageOriginatorSponsorsView = require('./NonDepLicenseMortgageOriginatorSponsorsView');
var SubmitInitiateAnnualReport = require('../services/SubmitInitiateAnnualReport');

require('../styles/NonDepLicenseDashboardView.css');
var tmplNonDepLicenseDashboardView = require('../templates/NonDepLicenseDashboardView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplNonDepLicenseDashboardView,

  className: 'non-dep-license-dashboard-view container-fluid',

  ui: {
    nmlsLabelContainer: '.nmls-label-container',
    licenseActionDropDown: '.dropdown-container',
    headerBar: '.header-bar-container',
    breadcrumb: '.breadcrumb-container',
    csLinkContainer: '.cs-link-container',
    licenseDashboard: '.license-dashboard-container',
    flaggedLabelContainer: '.flagged-label-container'
  },

  regions: {
    nmlsLabelContainer: '@ui.nmlsLabelContainer',
    licenseActionDropDown: '@ui.licenseActionDropDown',
    headerBar: '@ui.headerBar',
    breadcrumb: '@ui.breadcrumb',
    csLinkContainer: '@ui.csLinkContainer',
    licenseDashboard: '@ui.licenseDashboard',
    flaggedLabelContainer: '@ui.flaggedLabelContainer'
  },

  onBeforeShow: function() {
    var entityId = this.model.get('entityId');
    var entityName = this.model.get('entityName');
    var licenseId = this.model.get('licenseId');
    var licenseType = this.model.get('licenseType');
    var licenseTypeId = this.model.get('licenseTypeId');
    var licenseStatusId = this.model.get('licenseStatusId');
    var licenseNumber = this.model.get('licenseNumber');
    var isNMLSLicense = this.model.get('isNMLSLicense');
    var isBranchLicense = this.model.get('isBranch');
    var officeManagerIndividualId = modelGet(this.model, 'officeManager.id');
    var isDebtManagementLicense = _.contains(['7', '8', '9', '10'], licenseTypeId);
    var isMortgageLicense = _.contains(['17', '19', '21', '23', '29', '31'], licenseTypeId);
    var isMortgageOriginator = _.contains(['25', '36'], licenseTypeId);
    var isDoBSManagedLicenseType = this.model.get('isDOBSManagedLicenseType');
    var isDOBSAnnualReport = this.model.get('isDOBSAnnualReport');
    var isRetiredLicenseType = this.model.get('isRetiredLicenseType');
    var isAbleToBeRenewed = _.contains(['1', '2', '11'], licenseStatusId);
    var inactiveLicense = _.contains(['3', '6', '8', '10', '21'], licenseStatusId);
    var hasInProgressExam = this.model.get('hasInProgressExam') === '1';
    var isInternal = this.options.viewMode === 'int';
    var isExternal = this.options.viewMode === 'ext';
    var accessLevelId = this.model.get('accessLevelId');
    var isManagerOrAdmin = accessLevelId === 20 || accessLevelId === 30;
    // var isExamDirectorOrManager = _.some(
    //  ['ND - Exam Director', 'ND - Regional Director East', 'ND - Regional Director West'],
    //  userHasRole
    //);
    //  var isComplianceManagerOrExaminer = _.some(['ND - Compliance Examiner', 'ND - Compliance Manager'], userHasRole);
    if (isNMLSLicense) {
      this.showChildView(
        'nmlsLabelContainer',
        new NMLSLabelView({
          model: new Backbone.Model({
            objectName: 'license'
          })
        })
      );
    }
    var isLicenseOffice = userHasRole('ND - Licensing Office');
    var isComplianceOffice = userHasRole('ND - Compliance Office');
    var isExamOffice = userHasRole('ND - Exams Office');

    var dropDownOptions = [];

    if (isExternal) {
      if (!isNMLSLicense && isManagerOrAdmin && !inactiveLicense) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Apply to Edit Branch Manager',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/branch-manager-change'
          },
          {
            text: 'Apply to Edit Contacts',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/contacts-change'
          },
          {
            text: 'Apply to Edit Address',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/change-address'
          },
          {
            text: 'Apply to Edit D/B/A',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/edit-dba'
          },
          {
            text: 'Surrender License',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/surrender'
          }
        ]);
      }
      if (isDebtManagementLicense && isManagerOrAdmin && !inactiveLicense) {
        dropDownOptions = dropDownOptions.concat([
          {
            text: 'Apply to Change of Status of Counselors',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/change-counselors-status'
          },
          {
            text: 'Apply to Change of Status of Certification',
            link: '#ext/nondep/entity/' + entityId + '/license/' + licenseId + '/change-certification-status'
          }
        ]);
      }
    } else {
      dropDownOptions.push({
        text: 'Generate Letter',
        link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/doc-gen'
      });

      if (isComplianceOffice) {
        dropDownOptions.push({
          text: 'Create Compliance Request',
          link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/new-compliance'
        });
      }

      if (isExamOffice && !hasInProgressExam) {
        dropDownOptions.push({
          text: 'Initiate Exam',
          link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/initiate-exam'
        });
      }

      if (
        isLicenseOffice &&
        isDoBSManagedLicenseType &&
        !isRetiredLicenseType &&
        isAbleToBeRenewed &&
        !isBranchLicense &&
        !isNMLSLicense
      ) {
        dropDownOptions.push({
          text: 'Initiate License Renewal',
          link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/initiate-license-renewal'
        });
      }

      if (isComplianceOffice && isDOBSAnnualReport) {
        dropDownOptions.push({
          text: 'Initiate Annual Report',
          onClick: this.onClickInitiateAnnualReport.bind(this)
        });
      }

      var editOfficeManagerLink = '';
      if (officeManagerIndividualId) {
        editOfficeManagerLink = '#nondep/individual/' + officeManagerIndividualId + '/edit';
      } else {
        editOfficeManagerLink = '#nondep/entity/' + entityId + '/license/' + licenseId + '/branch-manager';
      }

      if (isLicenseOffice) {
        dropDownOptions = dropDownOptions.concat([
          {
            isDivider: true
          },
          {
            text: 'Edit General Information',
            link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/edit-general-information'
          },
          {
            text: 'Edit License Contacts',
            link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/edit-license-contacts'
          },
          {
            text: 'Edit Branch Manager',
            link: editOfficeManagerLink
          },
          {
            isDivider: true
          },
          {
            text: 'Add Bond',
            link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/add-bond'
          }
        ]);
      }

      dropDownOptions = dropDownOptions.concat([
        {
          isDivider: true
        },
        {
          text: 'Initiate Correspondence',
          link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/initiate-correspondence'
        },
        {
          isDivider: true
        },
        {
          text: 'View License History',
          link: '#nondep/entity/' + entityId + '/license/' + licenseId + '/history'
        }
      ]);
    }

    this.showChildView(
      'licenseActionDropDown',
      new DropdownView({
        rightAlign: true,
        model: new Backbone.Model({
          name: 'License Actions',
          options: dropDownOptions
        })
      })
    );

    this.showChildView(
      'headerBar',
      new LicenseHeaderBarView({
        model: new Backbone.Model(this.model.get('header'))
      })
    );

    if (isInternal && modelGet(this.model, 'header.entityIsFlagged')) {
      this.showChildView(
        'flaggedLabelContainer',
        new FlaggedLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'breadcrumb',
      new BreadcrumbView({
        breadcrumbs: [
          {
            link: isInternal ? '#nondep/dashboard' : '#ext/nondep/dashboard',
            text: 'Home'
          },
          {
            link: isInternal
              ? '#nondep/entity/' + entityId + '/dashboard'
              : '#ext/nondep/entity/' + entityId + '/dashboard',
            text: entityName
          },
          {
            text: 'License #' + licenseNumber + ' - ' + licenseType
          }
        ]
      })
    );

    var sideNavTabs = [
      {
        id: 'generalInformation',
        title: 'General Information',
        view: new NonDepLicenseGeneralInformationView({
          viewMode: this.options.viewMode,
          model: this.model
        })
      },
      {
        id: 'officeManager',
        title: 'Branch Manager',
        view: new NonDepLicenseOfficeManagerView({
          viewMode: this.options.viewMode,
          model: new Backbone.Model(this.model.get('officeManager'))
        })
      },
      {
        id: 'licenseContacts',
        title: 'License Contacts',
        view: new NonDepLicenseContactsView({
          viewMode: this.options.viewMode,
          collection: new Backbone.Collection(this.model.get('licenseContacts'))
        })
      }
    ];

    if (isDebtManagementLicense) {
      sideNavTabs.push({
        id: 'creditCounselors',
        title: 'Credit Counselors',
        view: new PanelGridView({
          columns: [
            {
              name: 'firstName',
              label: 'First Name',
              width: 15
            },
            {
              name: 'lastName',
              label: 'Last Name',
              width: 15
            },
            {
              name: 'certifyingOrganization',
              label: 'Certifying Organization',
              width: 20
            },
            {
              name: 'certificateNumber',
              label: 'Certificate Number',
              width: 20
            },
            {
              name: 'expirationDate',
              label: 'Expiration Date',
              width: 15
            },
            {
              name: 'status',
              label: 'Status',
              width: 15
            }
          ],
          collection: new Backbone.Collection(this.model.get('creditCounselors')),
          emptyText: 'No Credit Counselors'
        })
      });
    } else if (isMortgageLicense) {
      sideNavTabs.push({
        id: 'sponsorships',
        title: 'Sponsorships',
        view: new NonDepLicenseMortgageOriginatorSponsorshipsView({
          viewMode: this.options.viewMode,
          collection: new Backbone.Collection(this.model.get('mortgageOriginatorSponsorships'))
        })
      });
    } else if (isMortgageOriginator) {
      sideNavTabs.push({
        id: 'sponsors',
        title: 'Sponsors',
        view: new NonDepLicenseMortgageOriginatorSponsorsView({
          viewMode: this.options.viewMode,
          collection: new Backbone.Collection(this.model.get('mortgageOriginatorSponsors'))
        })
      });
    }
    var CSFolderId = modelGet(this.model, 'CSFolderId');
    var entityCSFolderId = modelGet(this.model, 'entityCSFolderId');
    if (isInternal) {
      this.showChildView(
        'csLinkContainer',
        new ContentServerLinkView({
          model: new Backbone.Model({
            buttonText: 'Open License Folder',
            contentServerId: CSFolderId ? CSFolderId : entityCSFolderId
          })
        })
      );

      sideNavTabs.splice(1, 0, {
        id: 'exams',
        title: 'Exams',
        view: Radio.channel('nd').request('build:license-exams-grid-view', licenseId)
      });

      sideNavTabs.splice(2, 0, {
        id: 'bonds',
        title: 'License Bonds',
        view: new NonDepLicenseBondsView({
          viewMode: this.options.viewMode,
          collection: new Backbone.Collection(this.model.get('bonds'))
        })
      });

      sideNavTabs.splice(3, 0, {
        id: 'certificateHistory',
        title: 'Certificate History',
        view: new NonDepLicenseCertificateHistoryView({
          viewMode: this.options.viewMode,
          model: this.model
        })
      });

      sideNavTabs.splice(4, 0, {
        id: 'addressHistory',
        title: 'Previous Address',
        view: new NonDepLicenseAddressHistoryView({
          viewMode: this.options.viewMode,
          model: this.model
        })
      });

      sideNavTabs = sideNavTabs.concat([
        {
          id: 'compliance',
          title: 'Compliance',
          view: Radio.channel('nd').request('build:license-compliance-grid-view', licenseId)
        },
        {
          id: 'notes',
          title: 'Notes',
          view: Radio.channel('nd').request('build:notes-view', {
            objectId: this.model.get('licenseId'),
            objectTypeId: 'LICENSE'
          })
        },
        {
          id: 'auditLog',
          title: 'Audit Log',
          view: Radio.channel('nd').request('build:audit-log-view', {
            objectId: this.model.get('licenseId'),
            objectTypeId: 'LICENSE'
          })
        }
      ]);
    }

    this.dashboard = new SideNavSectionView({
      collection: new Backbone.Collection(sideNavTabs)
    });
    this.showChildView('licenseDashboard', this.dashboard);
  },

  onClickInitiateAnnualReport: function() {
    var licenseId = this.model.get('licenseId');
    var entityId = this.model.get('entityId');
    var message = 'Are you sure you want to create an annual report task?';

    UIUtil.showModal({
      title: 'Confirm Creating Annual Report Task',
      view: new ConfirmationModalView({
        confirmText: 'Initiate Annual Report',
        model: new Backbone.Model({
          message: message,
          onConfirm: function() {
            return SubmitInitiateAnnualReport(licenseId);
          },
          onConfirmDone: function() {
            Radio.channel('navigate').trigger('show:int:nd:license-dashboard', entityId, licenseId);
          }
        })
      })
    });
  }
});
