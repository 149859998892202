/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Marionette = require('backbone.marionette');
var Ladda = require('ladda');
var Radio = require('backbone.radio');
var moment = require('moment');
var Session = require('psa-core/Session');
var Syphon = require('backbone.syphon');

var modelGet = require('common/util/modelGet');
var FlaggedLabelView = require('common/views/FlaggedLabelView');

var EnforcementActionFormView = require('../../views/EnforcementActionFormView');
var ComplianceJumpToDropdownView = require('../../views/ComplianceJumpToDropdownView');
var SubmitManagerReviewEnforcementActionTask = require('../../services/SubmitManagerReviewEnforcementActionTask');

require('./ComplianceManagerReviewEnforcementActionView.css');
var tmplComplianceManagerReviewEnforcementActionView = require('./ComplianceManagerReviewEnforcementActionView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplComplianceManagerReviewEnforcementActionView,

  className: 'non-dep-compliance-manager-review-enforcement-action-view container-fluid',

  ui: {
    headerBar: '.header-bar-container',
    jumpTo: '.jump-to-container',
    breadcrumb: '.breadcrumb-container',
    summary: '.summary-container',
    enforcementActionReviewForm: '.review-enforcement-action-form',
    enforcementAction: '.enforcement-action-form-container',
    reviewComments: '.review-comments',
    flaggedLabelContainer: '.flagged-label-container',
    submit: '.btn-submit'
  },

  regions: {
    headerBar: '@ui.headerBar',
    jumpTo: '@ui.jumpTo',
    breadcrumb: '@ui.breadcrumb',
    summary: '@ui.summary',
    enforcementAction: '@ui.enforcementAction',
    flaggedLabelContainer: '@ui.flaggedLabelContainer'
  },

  events: {
    'click @ui.submit': 'onClickSubmit'
  },

  behaviors: {
    TaskBehavior: {},
    ToggleBehavoir: {
      toggles: [
        {
          target: '.review-comments-form-group',
          group: '.review-decision-select',
          valuesToShow: ['2106']
        }
      ]
    }
  },

  onBeforeShow: function() {
    var compliance = this.model.get('complianceDetails') || {};
    var entityIsFlagged = modelGet(this.model, 'compliance.entityIsFlagged', []);

    this.showChildView('headerBar', this.options.complianceHeaderBar);

    if (entityIsFlagged) {
      this.showChildView(
        'flaggedLabelContainer',
        new FlaggedLabelView({
          model: new Backbone.Model({
            objectName: 'entity'
          })
        })
      );
    }

    this.showChildView(
      'jumpTo',
      new ComplianceJumpToDropdownView({
        model: new Backbone.Model({
          compliance: compliance
        })
      })
    );

    this.showChildView('breadcrumb', this.options.complianceBreadcrumbs);

    this.showChildView('summary', this.options.complianceSummary);

    this.enforcementAction = new EnforcementActionFormView({
      isReadOnly: this.options.isReadOnly,
      model: this.model
    });

    this.showChildView('enforcementAction', this.enforcementAction);

    var complianceDetails = this.model.get('complianceDetails');
    if (complianceDetails.enforcementActionReviewComments) {
      this.ui.reviewComments.val('\n\n' + complianceDetails.enforcementActionReviewComments);
    }
  },

  validate: function() {
    return this.ui.enforcementActionReviewForm.parsley().whenValidate();
  },

  getFormData: function() {
    var formData = Syphon.serialize(this);

    formData.taskId = this.model.get('task').taskId;
    formData.complianceId = this.model.get('complianceDetails').complianceId;

    if (formData.enforcementActionReviewComments) {
      var timestamp = moment().format('MM/DD/YYYY h:mm A');
      var userName = Session.user.description;
      formData.enforcementActionReviewComments =
        '[' + userName + ' - ' + timestamp + '] \n' + formData.enforcementActionReviewComments;
    }

    return formData;
  },

  onClickSubmit: function(e) {
    e.preventDefault();

    this.validate().done(
      function() {
        var laddaContext = Ladda.create(this.ui.submit.get(0));
        laddaContext.start();
        SubmitManagerReviewEnforcementActionTask(this.getFormData())
          .done(function() {
            Radio.channel('navigate').trigger('show:int:nd:dashboard');
          })
          .fail(function() {
            laddaContext.stop();
          });
      }.bind(this)
    );
  }
});
