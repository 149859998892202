var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.task : depth0)) != null ? stack1.activity : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar-container\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<div class=\"col-sm-8 col-sm-offset-2 notification-container\">\r\n  <div class=\"m-b-lg\">\r\n    <b>"
    + ((stack1 = (helpers["default"] || (depth0 && depth0["default"]) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.notification : depth0)) != null ? stack1.message : stack1),"--",{"name":"default","hash":{},"data":data})) != null ? stack1 : "")
    + "</b>\r\n    <br /><br />\r\n    <b>SAVE OR PRINT before clicking the Acknowledgement button. Access to this notification WILL BE LOST once Acknowledgment is clicked.</b>\r\n  </div>\r\n  <div class=\"document-container\"></div>\r\n  <button class=\"btn btn-primary submit ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n    <span class=\"ladda-label\">Acknowledge</span>\r\n  </button>\r\n  <button class=\"btn btn-primary print ladda-button\" data-style=\"expand-right\" type=\"button\">\r\n    <span class=\"ladda-label\">Print</span>\r\n  </button>\r\n</div>";
},"useData":true});