/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complaintId) {
  var deferred = $.Deferred();

  $.soap({
    body: {
      GetComplaintLitigationHoldStatus: {
        $xmlns: 'http://dobs.pa.gov/cs/complaint/bpm/1.0',
        ComplaintID: complaintId
      }
    },
    blueprint: {
      hasLitigationHold: '$..HasLitigationHold',
      litigationHoldStatusNote: '$..LitigationHoldStatusNote'
    }
  })
    .done(function(response) {
      cleanUpResponse(response, {
        cleanUpTextKeys: true
      });

      response.hasLitigationHold = response.hasLitigationHold === '1';

      deferred.resolve(response);
    })
    .fail(function(err) {
      deferred.reject(err);
    });

  return deferred.promise();
};
