var Handlebars = require("handlebars/dist/handlebars");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "\r\n<div class=\"page-title clearfix\">\r\n  <h1 class=\"pull-left m-n p-n\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.entityName : stack1), depth0))
    + "</h1>\r\n</div>\r\n<div class=\"header-bar\"></div>\r\n<div class=\"breadcrumb-container\"></div>\r\n<form class=\"form-horizontal edit-compliance-contact-form\">\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">First Name</label>\r\n    <div class=\"col-sm-6\">\r\n      <input type=\"text\" name=\"firstName\" class=\"form-control\" placeholder=\"First Name\"\r\n        data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group required\">\r\n    <label class=\"control-label col-sm-3\">Last Name</label>\r\n    <div class=\"col-sm-6\">\r\n      <input type=\"text\" name=\"lastName\" class=\"form-control\" placeholder=\"Last Name\"\r\n        data-parsley-required=\"true\" data-parsley-maxlength=\"100\" />\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Email</label>\r\n    <div class=\"col-sm-6\">\r\n      <input type=\"text\" name=\"email\" class=\"form-control\" placeholder=\"Email\" data-parsley-maxlength=\"254\"\r\n          data-parsley-type=\"email\" />\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"control-label col-sm-3\">Phone Number</label>\r\n    <div class=\"col-sm-6\">\r\n      <input type=\"text\" name=\"phoneNumber\" class=\"form-control phone-format\" placeholder=\"Phone Number\" \r\n          data-parsley-maxlength=\"20\"/>\r\n    </div>\r\n  </div>\r\n  <div class=\"form-group\">\r\n    <label class=\"col-sm-1\"></label>\r\n    <div class=\"col-sm-8\">\r\n      <div class=\"address\"></div>\r\n    </div>\r\n  </div>\r\n</form>\r\n<div class=\"col-sm-6 col-sm-offset-3 m-b-lg\">\r\n  <a class=\"btn btn-default btn-cancel\" href=\"#nondep/compliance/case/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0["case"] : depth0)) != null ? stack1.complianceId : stack1), depth0))
    + "\">Cancel</a>\r\n  <button class=\"btn btn-primary btn-submit\">Update Compliance Contact</button>\r\n</div>\r\n";
},"useData":true});