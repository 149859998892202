/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var cleanUpResponse = require('common/util/cleanUpResponse');

module.exports = function(complianceCase) {
  var defer = $.Deferred();

  $.soap({
    body: {
      SubmitComplianceCaseRequest: {
        $xmlns: 'http://dobs.pa.gov/nd/compliance/bpm/1.0',
        EntityID: complianceCase.entityId,
        LicenseID: complianceCase.licenseId,
        OperatingAsLicenseType: complianceCase.operatingAsType,
        ComplianceViolations: {
          ComplianceViolationID: complianceCase.violationCodes
        },
        BackgroundInformation: complianceCase.backgroundInformation,
        Contact: {
          Address: {
            AddressLine1: complianceCase.addressLine1,
            AddressLine2: complianceCase.addressLine2,
            County: complianceCase.county,
            City: complianceCase.city,
            State: complianceCase.state,
            PostalCode: complianceCase.postalCode,
            Country: complianceCase.country
          },
          FirstName: complianceCase.firstName,
          LastName: complianceCase.lastName,
          Email: complianceCase.email,
          PhoneNumber: complianceCase.phoneNumber
        }
      }
    },
    blueprint: {
      complianceId: '$..ComplianceID'
    }
  })
    .done(function(response) {
      cleanUpResponse(response);

      defer.resolve(response.complianceId);
    })
    .fail(function(err) {
      defer.reject();
    });

  return defer.promise();
};
