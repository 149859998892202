/*************************************************************************
 *
 * c20g CONFIDENTIAL
 * __________________
 *
 *  [2007] - [2019] Counterpoint Consulting, Incorporated
 *  All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Counterpoint Consulting Incorporated.
 * The intellectual and technical concepts contained
 * herein are proprietary to Counterpoint Consulting Incorporated
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Counterpoint Consulting Incorporated.
 */

var Backbone = require('backbone');
var Marionette = require('backbone.marionette');
var Syphon = require('backbone.syphon');
var bodybuilder = require('bodybuilder');
var Ladda = require('ladda');

var GridView = require('psa-marionette/views/GridView');
var PageableCollection = require('psa-backbone/models/PageableCollection');

var ButtonLinkCell = require('common/backgrid/ButtonLinkCell');
var ElasticSearchSummaryView = require('common/views/ElasticSearchSummaryView');
var SearchSecIndividuals = require('../../common/services/SearchSecIndividuals');

var SecInternalSidebarNavView = require('./SecInternalSidebarNavView');

require('../styles/SecIndividualSearchView.css');
var tmplSecIndividualSearchView = require('../templates/SecIndividualSearchView.hbs');

module.exports = Marionette.LayoutView.extend({
  template: tmplSecIndividualSearchView,

  className: 'sec-individual-search-view container-fluid',

  ui: {
    sideBar: '.side-bar-container',
    searchResultsContainer: '.search-results-container',
    search: '.search',
    clearFilters: '.clear-filters',
    formInputs: ':input',
    searchSummary: '.search-summary'
  },

  regions: {
    sideBar: '@ui.sideBar',
    searchResultsContainer: '@ui.searchResultsContainer',
    searchSummary: '@ui.searchSummary'
  },

  events: {
    'click @ui.search': 'onClickSearch',
    'click @ui.clearFilters': 'onClickClearFilters',
    'keydown @ui.formInputs': 'onKeydownOfInputs'
  },

  onBeforeShow: function() {
    this.showChildView('sideBar', new SecInternalSidebarNavView());

    this.searchSummaryView = new ElasticSearchSummaryView({
      model: new Backbone.Model({
        searchLimit: this.model.get('searchLimit')
      })
    });
    this.showChildView('searchSummary', this.searchSummaryView);

    this.searchResults = new GridView({
      columns: [
        {
          name: 'lastName',
          label: 'Last Name',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'firstName',
          label: 'First Name',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'middleInitial',
          label: 'Middle Initial',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: 'crdNumber',
          label: 'CRD Number',
          cell: 'string',
          headerCell: 'custom',
          width: 23,
          editable: false
        },
        {
          name: '',
          label: '',
          cell: ButtonLinkCell.extend({
            name: 'Open',
            newTab: true,
            buttonClass: 'btn-primary btn-sm',
            href: function(model) {
              var individualId = model.get('individualId');
              return '#sec/individual/' + individualId + '/dashboard';
            }
          }),
          headerCell: 'custom',
          sortable: false,
          editable: false,
          width: 8
        }
      ],
      collection: new PageableCollection([], { pageSize: 25 }),
      emptyText: 'No Individuals Found'
    });

    this.showChildView('searchResultsContainer', this.searchResults);

    this.$('.backgrid.table').addClass('table-bordered');
  },

  onKeydownOfInputs: function(e) {
    // Searches on press of enter
    if (e.keyCode === 13) {
      this.onClickSearch(e);
    }
  },

  onClickSearch: function() {
    var self = this;
    var data = Syphon.serialize(this, {
      inputReaders: Syphon.SearchInputReaderSet
    });
    // If no inputs were given, do not perform a search.
    if (
      !(
        data.firstName ||
        data.lastName ||
        data.middleInitial ||
        data.crdNumber ||
        data.otherIdNumber ||
        data.orgName ||
        data.orgCRDNumber ||
        data.licenseTypeId ||
        data.licenseStatusId
      )
    ) {
      return;
    }
    this.searchSummaryView.reset();

    var l = Ladda.create(this.ui.search[0]);
    l.start();
    var body = bodybuilder();

    if (data.lastName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'lastName', {
          query: data.lastName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'lastName', data.lastName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.firstName) {
      body.query('bool', function(b) {
        b.orQuery('match', 'firstName', {
          query: data.firstName,
          fuzziness: 'AUTO',
          analyzer: 'standard'
        });
        b.orQuery('wildcard', 'firstName', data.firstName.toLowerCase() + '*');
        return b;
      });
    }

    if (data.middleInitial) {
      body.query('match', 'middleInitial', data.middleInitial);
    }

    if (data.crdNumber) {
      body.query('wildcard', 'crdNumber', data.crdNumber + '*');
    }

    if (data.otherIdNumber) {
      body.query('wildcard', 'otherIdNumber', data.otherIdNumber + '*');
    }

    if (data.orgName) {
      body.query('nested', 'path', 'organizations', function(orgBody) {
        orgBody.query('bool', function(nameBody) {
          nameBody.orQuery('match', 'organizations.name', {
            query: data.orgName,
            fuzziness: 'AUTO',
            analyzer: 'standard'
          });
          nameBody.orQuery('wildcard', 'organizations.name', data.orgName.toLowerCase() + '*');
          nameBody.orQuery('match', 'organizations.name.phonetic', data.orgName);
          return nameBody;
        });
        return orgBody;
      });
    }

    if (data.orgCRDNumber) {
      body.query('nested', 'path', 'organizations', function(orgBody) {
        orgBody.query('wildcard', 'organizations.crdNumber', data.orgCRDNumber + '*');
        return orgBody;
      });
    }

    if (data.licenseTypeId) {
      body.query('nested', 'path', 'licenses', function(licenseBody) {
        licenseBody.query('match', 'licenses.typeId', data.licenseTypeId);
        return licenseBody;
      });
    }

    if (data.licenseStatusId) {
      body.query('nested', 'path', 'licenses', function(licenseBody) {
        licenseBody.query('match', 'licenses.statusId', data.licenseStatusId);
        return licenseBody;
      });
    }

    body.notQuery('term', 'isDeleted', '1');

    body.size(this.model.get('searchLimit'));
    var bodyObj = body.build();
    // Keep for testing
    // console.log(JSON.stringify(bodyObj, null, 2));
    SearchSecIndividuals(bodyObj)
      .done(function(data) {
        l.stop();
        self.searchResults.collection.fullCollection.reset(data.hits);
        self.searchSummaryView.display(data);
      })
      .fail(function(err) {
        l.stop();
      });
  },

  onClickClearFilters: function() {
    this.ui.formInputs.val('');
  }
});
